const isIOS = navigator.userAgent.match(/(iPod|iPhone|iPad)/) && navigator.userAgent.match(/AppleWebKit/);

const EVENT = isIOS ? 'deviceorientation' : 'deviceorientationabsolute';

class Orientation {
  constructor(orientation) {
    this.eventListener = null;
    this.permission = false;
    this.callback = null;
    this.listening = false;
    this.orientation = orientation || null;
    // this.MAX_RETRY = 5;
    this.handler = this.handler.bind(this);
    this.points = [
      'N',
      'NNE',
      'NE',
      'ENE',
      'E',
      'ESE',
      'SE',
      'SSE',
      'S',
      'SSW',
      'SW',
      'WSW',
      'W',
      'WNW',
      'NW',
      'NNW'
    ];
  }

  isValidPoint(point) {
    return this.points.some(p => p === point);
  }

  static isValidOrientation(value) {
    if (isNaN(value) || value === null || value === '') {
      return false
    }
    return true
  }

  compassPoint(bearing) {
    let d = 360 / 16;
    const points = [...this.points, 'N'];
    let j = 0;
    let name = '';
    if (bearing >= -d / 2) {
      j = Math.floor((bearing + d / 2) / d);
    } else {
      j = 16 - Math.floor((-bearing + d / 2) / d);
    }
    name = points[j];
    return name;
  }

  bearing(point) {
    if (point == null || !this.isValidPoint(point)) {
      return null;
    }
    const index = this.points.indexOf(point);
    let bearing;
    if (index <= 8) {
      bearing = index * 22.5;
    } else {
      bearing = (index - 16) * 22.5;
    }
    return bearing;
  }

  convert(angle, from = 360, to = 180) {
    if (!Orientation.isValidOrientation(angle)) {
      return null;
    }
    if (from === 360 && to === 180) {
      if (angle > 180) {
        return angle - 360;
      } else {
        return angle;
      }
    }
    else if (from === 180 && to === 360) {
      if (angle < 0) {
        return 360 - Math.abs(angle);
      } else {
        return angle;
      }
    }
    else {
      throw new Error('Invalid format');
    }
  }

  bearing360(point) {
    if (!this.isValidPoint(point)) {
      return 0;
    }
    const index = this.points.indexOf(point);
    return index * 22.5;
  }

  set(orientation) {
    if (orientation?.heading || orientation?.heading === 0) {
      this.orientation = orientation;
    }
  }

  supports() {
    try {
      if (sessionStorage.getItem('orientationPermission') === 'denied') {
        return false;
      }
    } catch { }
    return DeviceMotionEvent && typeof DeviceMotionEvent.requestPermission === 'function';
  }

  /**
   *
   * @returns {Promise<string>} device motion permission response 'granted' or 'denied'
   */
  getPermission() {
    return new Promise((resolve, reject) => {
      if (this.supports()) {
        DeviceMotionEvent.requestPermission()
          .then(res => {
            if (res === 'granted') {
              try {
                sessionStorage.removeItem('orientationPermission');
              } catch { }
              this.permission = true;
              resolve(res);
            } else {
              try {
                sessionStorage.setItem('orientationPermission', 'denied');
              } catch { }
              this.permission = false;
              reject(res);
            }
          })
          .catch(err => {
            this.permission = false;
            reject(err);
          });
      } else {
        reject(new Error('No device detected'));
      }
    });
  }

  formatter(event) {
    const response = {
      alpha: null,
      beta: null,
      gamma: null,
      heading: null
    };
    response.alpha = event?.alpha;
    response.beta = event?.beta;
    response.gamma = event?.gamma;
    response.heading = event?.webkitCompassHeading;
    if (!response.heading && !isIOS && Orientation.isValidOrientation(response.alpha)) {
      response.heading = Math.abs(event.alpha - 360);
    }
    if (response.alpha || response.heading) {
      this.permission = true;
    }
    return response;
  }

  stop() {
    window.removeEventListener(EVENT, this.handler, 1000);
  }

  handler(event) {
    this.orientation = this.formatter(event);
    if (this.callback) {
      this.callback(this.orientation);
    }
  }

  onChange(callback) {
    this.callback = callback;
    window.addEventListener(EVENT, this.handler, 100);
  }

  get() {
    return new Promise((resolve, reject) => {
      // let count = 0;
      let resolved = false;

      setTimeout(() => {
        if (!resolved) {
          reject(new Error('Device orientation not available'));
        }
      }, 1000);

      this.onChange(data => {
        resolved = true;
        // if (data || count > this.MAX_RETRY) {
        this.stop();
        resolve(data);
        // }
      });
    });
  }

  angle() {
    if (this.orientation?.heading || this.orientation?.heading == 0) {
      return this.orientation.heading;
    }
    // const alpha = this.orientation?.alpha;
    // if ([null, undefined].includes(alpha)) return;
    // return Math.abs(alpha - 360).toFixed(0);
  }

  heading(heading) {

    let angle = this.angle();
    angle = this.convert(angle, 180, 360)
    if (heading !== undefined) {
      angle = heading;
    }

    if ([null, undefined].includes(angle)) return;

    angle = this.convert(angle, 180, 360)

    const index = Math.floor(angle / 22.5 + 0.5);

    const values = [
      'N',
      'NNE',
      'NE',
      'ENE',
      'E',
      'ESE',
      'SE',
      'SSE',
      'S',
      'SSW',
      'SW',
      'WSW',
      'W',
      'WNW',
      'NW',
      'NNW'
    ];

    return values[index % 16];
  }

  static validatedData(data) {
    const res = data || {
      alpha: null,
      beta: null,
      gamma: null,
      heading: null
    };
    Object.keys(res).forEach(k => {
      res[k] = Number(res[k]) || null
      if (res[k] === undefined) {
        res[k] = null;
      }
    });
    return res;
  }
}

export default Orientation;
