import { userRef } from '@/firebase/db';
import initializeLogRocket from '../logrocket';
export default {
  methods: {
    async watchAll() {
      let user = await this.currentUser();
      if (user) {
        this.watchProfile();
      }
    },
    async watchProfile() {
      let user = await this.currentUser();
      if (user) {
        userRef.doc(user.uid).onSnapshot(data => {
          const userData = {
            profile: { ...data.data() },
            email: user.email,
            uid: user.uid
          };
          initializeLogRocket({ ...userData, uid: user.uid });
          this.$store.commit('user', userData);

          const { type } = this.$store.state.loggedInAs;
          if (type === 'user') {
            this.$store.commit('loginAsUser');
          }

          if (userData.profile.peepsGallerySetting) {
            this.$store.dispatch('updateGallerySettingObj', userData.profile.peepsGallerySetting);
          }
        });
      }
    }
  }
};
