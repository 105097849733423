<template>
  <v-card class="mx-auto">
    <v-card-text>
      <div class="pt-3">
        <slot name="head"></slot>
      </div>
      <div>
        <slot
          ><h3 class="text-h5">{{ confirmMessage }}</h3></slot
        >
      </div>
      <div class="text-center mt-4">
        <v-btn
          :small="small"
          :color="confirmColor"
          v-bind="confirmAttrs || {}"
          @click="$emit('confirm')"
          >{{ confirmLabel }}
          <v-icon v-if="confirmIcon">{{ confirmIcon }}</v-icon></v-btn
        >
        <v-btn
          class="ml-1"
          :color="cancelColor"
          :small="small"
          dark
          v-bind="cancelAttrs || {}"
          @click="$emit('cancel')"
          >{{ cancelLabel }}
          <v-icon v-if="cancelIcon">{{ cancelIcon }}</v-icon></v-btn
        >
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      required: true
    },
    confirmLabel: {
      type: String,
      default: 'Yes'
    },
    confirmMessage: {
      type: String,
      default: 'Are you sure?'
    },
    cancelLabel: {
      type: String,
      default: 'No'
    },
    confirmIcon: {
      type: String,
      default: 'mdi-trash-can'
    },
    cancelIcon: {
      type: String,
      default: 'mdi-close'
    },
    confirmColor: {
      type: String,
      default: 'error'
    },
    cancelColor: {
      type: String,
      default: 'grey'
    },
    confirmAttrs: {
      type: Object
    },
    cancelAttrs: {
      type: Object
    },
    small: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style></style>
