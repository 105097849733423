<template>
  <v-card v-bind="$attrs">
    <v-card-title v-show="showTitle">
      <slot name="title"> Filter By </slot>
    </v-card-title>
    <v-card-text>
      <span
        class="ml-1 cursor-pointer"
        v-for="(item, index) in items"
        :key="index"
        @click="setValue(item)"
      >
        <v-chip
          class="cursor-pointer"
          style="margin-bottom: 2px"
          :dark="shouldBeDark(item)"
          :color="getChipColor(item)"
          :outlined="outlined && !active(item)"
        >
          {{ item[itemText] }}</v-chip
        >
      </span>
      <div v-if="value != clearValue && showClearButton" class="text-center mt-1">
        <span @click="emit(clearValue)">
          <cancel-button rounded>Clear Filter <v-icon>mdi-close</v-icon></cancel-button>
        </span>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: {
    activeDark: {
      type: Boolean,
      default: false
    },
    outlined: {
      type: Boolean,
      default: false
    },
    value: {
      type: [String, Array]
    },
    items: {
      type: Array
    },
    clearValue: {
      type: String,
      default: ''
    },
    itemText: {
      type: String,
      default: 'text'
    },
    itemValue: {
      type: String,
      default: 'value'
    },
    activeColor: {
      type: String,
      default: 'primary'
    },
    defaultColor: {
      type: String,
      default: 'secondary'
    },
    itemStyle: {
      type: Boolean,
      default: false
    },
    showClearButton: {
      type: Boolean,
      default: true
    },
    showTitle: {
      type: Boolean,
      default: true
    },
    multiple: {
      type: Boolean,
      default: false
    },
    default: {
      type: [String, Array],
      default: ''
    }
  },
  data() {
    return {
      lazyValue: null
    };
  },
  created() {
    this.setDefaultValue();
  },
  mounted() {
    this.emit(this.value);
  },
  components: {
    CancelButton: () => import('@/components/Core/CancelButton')
  },
  methods: {
    setDefaultValue() {
      if (this.multiple) {
        if (Array.isArray(this.value)) {
          this.lazyValue = Array.from(this.value || this.default);
        } else {
          this.lazyValue = this.value ? this.value : this.default;
        }
      } else {
        if (Array.isArray(this.value)) {
          this.lazyValue = this.value[0] || this.default;
        } else {
          this.lazyValue = this.value || this.default;
        }
      }
    },
    setValue(item) {
      let value = '';
      if (this.value === item[this.itemValue]) {
        value = '';
      } else {
        value = item[this.itemValue];
      }

      if (this.multiple) {
        if (value === '') {
          this.lazyValue = [''];
        } else {
          this.lazyValue = this.lazyValue.filter(v => !!v);
          const index = this.lazyValue.indexOf(value);
          if (index === -1) {
            this.lazyValue.push(value);
          } else {
            this.lazyValue.splice(index, 1);
            if (this.lazyValue.length === 0) {
              this.lazyValue = [''];
            }
          }
        }
      } else {
        this.lazyValue = value;
      }

      this.emit(this.lazyValue);
    },
    active(item) {
      if (Array.isArray(this.value)) {
        return this.lazyValue.includes(item[this.itemValue]);
      } else {
        return this.lazyValue === item[this.itemValue];
      }
    },
    shouldBeDark(item) {
      if (this.activeDark && item.value === this.value) {
        return true;
      } else {
        return this.itemStyle ? this.isDark(item) : false;
      }
    },
    emit(val) {
      this.$emit('input', val);
      const item = this.items.filter(i => i[this.itemValue] === val);
      if (item.length) {
        this.$emit(
          'update:text',
          item[0][this.itemText],
          item[0]?.style?.color || this.activeColor,
          item[0]?.style?.dark || this.activeDark
        );
      }
    },
    isDark(item) {
      return item?.style?.dark;
    },
    getChipColor(item) {
      let color = this.defaultColor;
      if (this.itemStyle) {
        color = item?.style?.color || this.defaultColor;
      } else {
        if (item[this.itemValue] == this.value) {
          color = this.activeColor;
        }
      }
      return color;
    }
  },
  watch: {
    value: {
      handler(value) {
        this.lazyValue = value;
      }
    }
  }
};
</script>

<style></style>
