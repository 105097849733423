import { getFirestore } from './firebase';
import config from '../config';
import { getCurrentTimestamp } from './firebase';

const privacy = {
  private: 'private',
  admin: 'admin',
  public: 'public'
};

const geojson = {
  type: 'geojson',
  height: '50vh',
  width: '100%',
  key: '',
  geojson: '',
  geostyle: '',
  organization: false
};

const defaultCols = () => {
  return {
    xs: 12,
    sm: 12,
    md: 12,
    lg: 12,
    xl: 12
  };
};

const defaultOffsets = () => {
  return {
    xs: 0,
    sm: 0,
    md: 0,
    lg: 0,
    xl: 0
  };
};

const types = {
  video: 'video',
  html: 'html',
  page: 'page',
  json: 'json',
  image: 'image',
  drive: 'drive',
  button: 'button',
  // buttonGroup: 'buttonGroup',
  navigation: 'navigation',
  favicon: 'favicon',
  spacer: 'spacer',
  style: 'style',
  line: 'line',
  form: 'form',
  breadcrumb: 'breadcrumb',
  geojson: 'geojson',
  geostyle: 'geostyle',
  geocircle: 'geocircle',
  geoscript: 'geoscript'
};

const data = {
  geojson
};

const getFormat = (type, cols) => {
  let res = {};
  if (Object.values(types).includes(type)) {
    res = { ...data[type], privacy: privacy.private };
  } else {
    return null;
  }
  if (cols) {
    res = { ...res, cols: defaultCols(), offsets: defaultOffsets() };
  }
  if (!res.key) {
    res.key = '';
  }
  if (res.type !== 'page') {
    res.privacy = privacy.public;
  }
  return res;
};

const getValidatedData = data => {
  const ret = { ...getFormat(data.type), ...data };
  if (ret.type === types.page) {
    ret.nodes.forEach((node, index) => {
      ret.nodes[index] = { ...getFormat(node.type, true), ...node };
    });
  }
  return ret;
};

const DocumentRes = { id: '', data: {} };

class CMS {
  constructor() {
    this.firestore = getFirestore();
    this.collection = config.firestore.cms;
    this.ref = this.firestore.collection(config.firestore.cms);
    this.versionRef = this.firestore.collection(config.firestore.versions);
    this.privacy = privacy;
  }
  get({ key, url, type, privacy, user, admin, organization }, callback) {
    let q = this.ref;

    if (key) {
      q = q.where('key', '==', key);
    }
    if (url) {
      q = q.where('url', '==', url);
    }
    if (type) {
      q = q.where('type', '==', type);
    }
    if (organization) {
      q = q.where('organization', '==', organization);
    }

    if (!privacy) {
      privacy = 'public';
    }

    if (privacy !== 'all') {
      q = q.where('privacy', '==', privacy);
      if (privacy === this.privacy.private) {
        q = q.where('user', '==', user || '');
      }
    }

    if (callback) {
      return q.onSnapshot(callback);
    } else {
      return q.get();
    }
  }
  parcelGeojson(parcelNumber, orgId) {
    return new Promise((resolve, reject) => {
      this.ref
        .where('type', '==', 'geojson')
        .where('parcel', '==', parcelNumber)
        .get()
        .then(res => {
          if (res.size) {
            let data = { id: res.docs[0].id, data: res.docs[0].data().geojson };

            if (res.size > 1) {
              const filteredParcel = res.docs.filter(doc => {
                return doc.data().organizationId === orgId && !doc.data().group;
              });
              if (filteredParcel[0]) {
                data = { id: filteredParcel[0].id, data: filteredParcel[0].data().geojson };
              }
            }

            resolve(data);
          } else {
            reject(new Error('Geojson not found'));
          }
        })
        .catch(err => reject(err));
    });
  }

  /**
   *
   * @param {String} key
   * @returns {Promise<{id, data}>}
   */

  geoJSON(key) {
    return new Promise((resolve, reject) => {
      this.ref
        .where('type', '==', 'geojson')
        .where('key', '==', key)
        .get()
        .then(res => {
          if (res.size) {
            const doc = res.docs[0];
            const responseData = { id: doc.id, data: doc.data() };
            if (responseData.data.key === key) {
              resolve(responseData);
            } else {
              reject(new Error('GeoJSON not found', key));
            }
          } else {
            reject(new Error('GeoJSON not found'));
          }
        })
        .catch(error => reject(error));
    });
  }

  /**
   *
   * @param {String} key
   * @returns {Promise<{id, data}>}
   */

  geocircle(key) {
    return new Promise((resolve, reject) => {
      this.ref
        .where('type', '==', 'geocircle')
        .where('key', '==', key)
        .get()
        .then(res => {
          if (res.size) {
            const doc = res.docs[0];
            const responseData = { id: doc.id, data: doc.data() };
            if (responseData.data.key === key) {
              resolve(responseData);
            } else {
              reject(new Error('Geocircle not found', key));
            }
          } else {
            reject(new Error('Geocircle not found'));
          }
        })
        .catch(error => reject(error));
    });
  }

  geostyle(key) {
    return new Promise((resolve, reject) => {
      this.ref
        .where('type', '==', 'geostyle')
        .where('key', '==', key)
        .get()
        .then(res => {
          if (res.size) {
            const doc = res.docs[0];
            const responseData = { id: doc.id, data: doc.data() };
            if (responseData.data.key === key) {
              resolve(responseData);
            } else {
              reject(new Error('Geostyle not found', key));
            }
          } else {
            reject(new Error('Geostyle not found'));
          }
        })
        .catch(error => reject(error));
    });
  }

  exists(key) {
    return new Promise((resolve, reject) => {
      this.ref
        .where('key', '==', key)
        .get()
        .then(res => {
          if (res.docs && res.docs.length && res.docs[0].exists) {
            if (res.docs[0].data().key === key) {
              resolve(true);
            } else {
              reject(new Error('Widget does not exists'));
            }
          } else {
            reject(new Error('Widget does not exists'));
          }
        })
        .catch(error => reject(error));
    });
  }

  /**
   *
   * @param {String} key
   * @returns {Promise<DocumentRes>}
   */
  detailByKey(key) {
    return new Promise((resolve, reject) => {
      this.ref
        .where('key', '==', key)
        .get()
        .then(q => {
          if (q.docs.length) {
            const data = { id: q.docs[0].id, data: q.docs[0].data() };
            if (data.data.key === key) {
              resolve(data);
            } else {
              reject(new Error('Document does not exists'));
            }
          } else {
            reject(new Error('Document does not exists'));
          }
        })
        .catch(error => reject(error));
    });
  }

  /**
   *
   * @param {String} id
   * @param {String} key
   * @returns {Promise<DocumentRes>}
   */
  detail(id, key) {
    id = String(id || '').trim();
    key = String(key || '').trim();
    if (id) {
      return new Promise((resolve, reject) => {
        this.ref
          .doc(id)
          .get()
          .then(q => {
            if (q.exists) {
              resolve({ id: q.id, data: q.data() });
            } else {
              reject(new Error('Document does not exists'));
            }
          });
      });
    } else if (key) {
      return this.detailByKey(key);
    } else {
      return new Promise(resolve => {
        resolve(null);
      });
    }
  }

  create({ type, key, data }) {
    return new Promise((resolve, reject) => {
      this.get({ key })
        .then(docs => {
          if (docs.docs.length) {
            return reject(new Error(`Widget <strong>${key}</strong> already exists`));
          } else {
            let obj = getFormat(type);
            obj = {
              ...obj,
              ...data,
              createdAt: getCurrentTimestamp(),
              updatedAt: getCurrentTimestamp()
            };
            obj.key = key;
            obj.type = type;
            resolve(this.ref.add(obj));
          }
        })
        .catch(err => {
          reject(err);
        });
    });
  }
}

export { CMS, privacy };
