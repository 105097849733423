import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

import Icons from '@/components/Core/Icons/';
import colors from '../design/_colors.scss';

const lightThemeColors = {
  'peeps-green': colors['peeps-green'],
  'peeps-light-yellow': colors['peeps-light-yellow'],
  nitrate: colors['nitrate'],
  peeps: colors.peeps,
  primary: colors.primary,
  'primary-light': colors['primary-light'],
  'success-light': colors['success-light'],
  secondary: colors.secondary,
  accent: colors.accent,
  error: colors.error,
  info: colors.info,
  success: colors.success,
  warning: colors.warning
};

const config = {
  customVariables: ['~/assets/variables.scss'],
  theme: {
    themes: {
      light: {
        ...lightThemeColors
      }
    }
  },
  icons: {
    values: {
      ...Icons
    }
  }
};

export default new Vuetify(config);
