const deviceOrientation = {
  alpha: null,
  beta: null,
  gamma: null
};

const parseJson = (data, returnOnError) => {
  if (!data) {
    return;
  }
  try {
    return JSON.parse(data);
  } catch (error) {
    if (returnOnError !== undefined) {
      return returnOnError;
    } else {
      return error.message;
    }
  }
};

const emailRule = [
  v => !!v || 'Enter a valid email',
  v => /.+@.+\..+/.test(v) || 'Enter a valid email'
];
const requiredRule = [v => !!v || 'This field is required'];
const passwordRule = [v => (!!v && v.length >= 6) || 'Password should be at least 6 characters'];

const parseFormat = (string, data) => {
  if (!string || !data) {
    return '';
  }
  let result = string;
  const regex = /({{[a-zA-Z0-9_ ]*}})/g;
  const match = string.match(regex);
  if (match) {
    match.forEach(match => {
      const key = /{{(.*)}}/g.exec(match)[1];
      result = result.replace(match, data[key] || '');
    });
  }
  return result;
};

const addOffsets = (obj, offsets) => {
  const res = { ...obj };

  if (offsets.xs) {
    res.offset = offsets.xs;
  }

  if (offsets) {
    Object.keys(offsets).forEach(offset => {
      res[`offset-${offset}`] = offsets[offset];
    });
  }

  return res;
};

const value = (obj, key) => {
  const keys = key.split('.');
  if (!keys.length || !keys[0]) {
    return '';
  }
  return keys.reduce((o, k) => {
    if (!o) {
      return undefined;
    }
    return o[k];
  }, obj);
};

const sort = ({ items, field, desc, generator }) => {
  let res = Array.from(items);

  const cmp = (a, b) => {
    if (a === undefined || b === undefined || a === null || b === null) {
      return 0;
    }
    if (typeof a === 'number') {
      if (a > b) {
        return 1;
      } else if (a < b) {
        return -1;
      } else {
        return 0;
      }
    } else if (typeof a === 'string') {
      return a.localeCompare(b);
    } else {
      throw new Error('Unknown field type');
    }
  };

  res.sort((a, b) => {
    let valueA, valueB;

    if (generator) {
      valueA = generator(a);
      valueB = generator(b);
    } else if (field) {
      valueA = value(a, field);
      valueB = value(b, field);
    } else {
      valueA = a;
      valueB = b;
    }

    return cmp(valueA, valueB);
  });

  if (desc) {
    res = res.reverse();
  }

  return res;
};

const search = ({ items, fields, query }) => {
  let res = Array.from(items);
  if (!query) {
    return res;
  }
  res = res.filter(item => {
    let res = false;

    fields.forEach(field => {
      res =
        res ||
        String(value(item, field))
          .toLowerCase()
          .includes(String(query).toLowerCase());
    });

    return res;
  });
  return res;
};

/**
 *
 * @returns {Promise<string>} device motion permission response 'granted' or 'denied'
 */
const getDeviceMotionEventPermission = () => {
  return new Promise((resolve, reject) => {
    if (DeviceMotionEvent && typeof DeviceMotionEvent.requestPermission === 'function') {
      DeviceMotionEvent.requestPermission()
        .then(res => resolve(res))
        .catch(err => reject(err));
    } else {
      reject(new Error('No device detected'));
    }
  });
};

// /**
//  *
//  * @returns {Promise<deviceOrientation>} device orientations
//  */
// const onDeviceOrientationChange = (cb) => {
//   return new Promise((resolve, reject) => {
//     getDeviceMotionEventPermission()
//       .then(() => {
//         const handler = function(event) {
//           const response = deviceOrientation;
//           response.alpha = event.alpha;
//           response.beta = event.beta;
//           response.gamma = event.gamma;
//           resolve(response);
//           if (event && response.alpha && response.beta && response.gamma) {
//             window.removeEventListener('deviceorientation', handler);
//           } else {
//             reject(new Error('Could not get device orientation'));
//           }
//         };
//         window.addEventListener('deviceorientation', handler);
//       })
//       .catch(err => reject(err));
//   });
// };

/**
 *
 * @returns {Promise<deviceOrientation>} device orientations
 */
const getDeviceOrientation = () => {
  return new Promise((resolve, reject) => {
    getDeviceMotionEventPermission()
      .then(() => {
        const handler = function (event) {
          const response = deviceOrientation;
          response.alpha = event.alpha;
          response.beta = event.beta;
          response.gamma = event.gamma;
          resolve(response);
          if (event && response.alpha && response.beta && response.gamma) {
            window.removeEventListener('deviceorientation', handler);
          } else {
            reject(new Error('Could not get device orientation'));
          }
        };
        window.addEventListener('deviceorientation', handler);
      })
      .catch(err => reject(err));
  });
};

/**
 *
 * @param {{first_name, last_name}} user
 * @returns
 */

const fullName = user => {
  if (!user || typeof user !== 'object') {
    return '';
  }
  let fullName = ``;
  fullName += user?.first_name || '';
  if (fullName) fullName += ` `;
  fullName += user?.last_name || '';
  return fullName;
};

/**
 *
 * @param {string} str
 * @returns {Array<string>}
 */

const parseTeam = str => {
  if (!str) {
    return [];
  }
  let result = str.split(',');
  result = result.map(i => (i || '').trim());
  result = result.filter(team => team);
  return result;
};

/**
 *
 * @param {Array<string>} a
 * @param {Array<string>} b
 * @returns {Boolean}
 */

const teamIncludes = (a, b) => {
  if (!a || !b) {
    return false;
  }
  for (let team of a) {
    if (b.includes(team)) {
      return true;
    }
  }
  return false;
};

const downloadFile = (data, filename) => {
  var hiddenElement = document.createElement('a');
  hiddenElement.href = data;
  hiddenElement.target = '_blank';
  hiddenElement.download = filename;
  hiddenElement.click();
};

const randomString = (length = 16) => {
  const result = [];
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result.push(characters.charAt(Math.floor(Math.random() * charactersLength)));
  }
  return result.join('');
};

const rowTotal = (items, row, fixed = 2) => {
  const total = items.reduce((value, parcel) => {
    return value + Number(parcel[row] || 0);
  }, 0);
  if (Number(total) === total && total % 1 === 0) {
    return total;
  } else {
    return Number(total).toFixed(fixed);
  }
};

const downloadJSON = (obj, filename) => {
  var dataStr = 'data:text/json;charset=utf-8,' + encodeURIComponent(JSON.stringify(obj));
  var downloadAnchorNode = document.createElement('a');
  downloadAnchorNode.setAttribute('href', dataStr);
  downloadAnchorNode.setAttribute('download', filename + '.json');
  document.body.appendChild(downloadAnchorNode);
  downloadAnchorNode.click();
  downloadAnchorNode.remove();
};

const dateDiff = (date1, date2, fmt = 'days') => {
  const moment = require('moment/moment');
  return moment(moment(date1).format('YYYY-MM-DD')).diff(moment(date2).format('YYYY-MM-DD'), fmt);
};

const randomNumber = () => {
  return Math.floor(100000 + Math.random() * 900000);
};

const setIntervalX = (callback, delay, repetitions) => {
  if (!repetitions) {
    throw new Error('repetitions is required');
  }
  var x = 0;
  var intervalID = window.setInterval(function () {
    ++x;
    callback(x);
    if (x === repetitions) {
      window.clearInterval(intervalID);
    }
  }, delay);
};

const parseTimestamp = (ts) => {
  let date = null;
  if (ts && typeof ts.getMonth === 'function') {
    date = new Date(ts);
  } else if (typeof ts === 'object') {
    date = new Date((ts.seconds || ts._seconds) * 1000);
  } else {
    date = new Date(ts);
  }
  if (date == 'Invalid Date') {
    return '';
  }
  return date;
}

/**
 * 
 * @param {Array<>} set 
 * @param {Array<>} subset 
 * @returns {Array<>}
 */
const complement = (set, subset) => {
  return set.filter(i => !subset.includes(i))
}

export {
  parseJson,
  emailRule,
  requiredRule,
  passwordRule,
  parseFormat,
  addOffsets,
  search,
  sort,
  value,
  getDeviceMotionEventPermission,
  getDeviceOrientation,
  fullName,
  parseTeam,
  teamIncludes,
  downloadFile,
  randomString,
  rowTotal,
  downloadJSON,
  dateDiff,
  randomNumber,
  setIntervalX,
  parseTimestamp,
  complement
};
