class Geolocation {
  constructor(data) {
    this.internalData = data;
    this.callback = null;
    this.handler = this.handler.bind(this);
    this.id = null;
  }

  set(data) {
    if (!data) return;
    this.internalData = data;
  }

  /**
   *
   * @returns {Promise<string>} device motion permission response 'granted' or 'denied'
   */
  getPermission() {
    return new Promise((resolve, reject) => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          () => {
            resolve();
          },
          err => {
            reject(err);
          }
        );
      } else {
        reject();
      }
    });
  }

  formatter(data) {
    return data;
  }

  stop() {
    navigator.geolocation.clearWatch(this.id);
    this.id = null;
  }

  /**
   *
   * @param {GeolocationPosition} e
   */
  handler(e) {
    this.callback({
      lat: e.coords.latitude,
      lng: e.coords.longitude,
      altitude: e.coords.altitude,
      altitudeAccuracy: e.coords.altitudeAccuracy,
      accuracy: e.coords.accuracy
    });
  }

  onChange(callback, error) {
    this.stop();
    this.callback = callback;
    if (navigator.geolocation) {
      this.id = navigator.geolocation.watchPosition(this.handler, error);
    } else {
      error(new Error('Your browser does not support HTML5 geolocation.'));
    }
  }

  static validatedData(data) {
    const res = data || {
      lat: null,
      lng: null,
      altitude: null,
      altitudeAccuracy: null,
      accuracy: null
    };
    Object.keys(res).forEach(key => {
      res[key] = Number(res[key]) || null;
    });
    return res;
  }
}

export default Geolocation;
