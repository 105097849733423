// import {userRef} from '../services/firestore'
import firebase from 'firebase/app';
import 'firebase/auth';
import { firestore, COLLECTION } from '../services/firestore';

class User {
  constructor(uid) {
    this.uid = uid;
    this.ref = firestore.collection(COLLECTION.user);
  }

  currentUser() {
    return firebase.auth().currentUser;
  }

  setUid(uid) {
    this.uid = uid;
  }

  get({ uid, email }) {
    if (!uid && !email) {
      uid = this.uid;
    }
    return new Promise((resolve, reject) => {
      if (uid) {
        this.ref
          .doc(id)
          .get()
          .then(res => {
            resolve({ id: res.id, data: res.data() });
          })
          .catch(err => reject(err));
      } else if (email) {
        this.ref
          .where('email', '==', email)
          .get()
          .then(res => {
            if (!res.size) {
              reject(new Error('No user found'));
            }
            resolve({ id: res.docs[0].id, data: res.docs[0].data() });
          })
          .catch(err => reject(err));
      } else {
        reject(new Error('uid or email is required'));
      }
    });
  }

  getProfile(id) {
    return new Promise((resolve, reject) => {
      this.ref
        .doc(id)
        .get()
        .then(res => {
          if (res.exists) {
            resolve({ data: res.data(), id: res.id });
          } else {
            reject();
          }
        })
        .catch(reject);
    });
  }

  async updateProfile(uid, obj) {
    if (!uid) {
      throw { message: 'uid is required' };
    }

    if (typeof uid !== 'string') {
      throw { message: 'invalid uid' };
    }

    const profile = await this.ref.doc(uid).get();
    const data = { ...profile.data() };
    const newData = { ...data, ...obj };

    return await this.ref.doc(uid).set(newData);
  }

  listUsers() {
    return this.ref.get();
  }
}

export default User;
