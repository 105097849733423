<template>
  <base-tooltip :bottom="bottom" v-bind="$attrs" v-on="$listeners">
    <template #activator="{ on }">
      <span v-on="on" @click="$emit('click')">
        <slot name="default"></slot>
      </span>
    </template>
    <span>
      {{ tooltip }}
    </span>
  </base-tooltip>
</template>

<script>
export default {
  props: {
    tooltip: {
      type: String,
      required: true
    },
    bottom: {
      type: Boolean,
      default: true
    }
  }
};
</script>

<style>
</style>
