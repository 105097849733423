import { getFirestore } from './firebase';

const ACCESS = {
  DEFAULT: '',
  USER: 'user',
  ORGANIZATION: 'organization'
};

const DEFAULT_FEATURES = [
  {
    id: 1,
    text: 'Water',
    value: 'water',
    disabled: false,
    access: ACCESS.DEFAULT
  },
  {
    id: 2,
    text: 'Septic',
    value: 'septic',
    disabled: false,
    access: ACCESS.DEFAULT
  },
  {
    id: 3,
    text: 'Energy',
    value: 'hvac',
    disabled: false,
    access: ACCESS.DEFAULT
  },
  {
    id: 4,
    text: 'Solar',
    value: 'solar',
    disabled: false,
    access: ACCESS.DEFAULT
  },
  {
    id: 5,
    text: 'Taxes',
    value: 'tax',
    disabled: false,
    access: ACCESS.DEFAULT
  },
  {
    id: 6,
    text: 'Electric',
    value: 'electric',
    disabled: true,
    access: ACCESS.DEFAULT
  },
  {
    id: 7,
    text: 'Vehicle',
    value: 'vehicle',
    disabled: true,
    access: ACCESS.DEFAULT
  },
  {
    id: 8,
    text: 'Land',
    value: 'land',
    disabled: false,
    access: ACCESS.ORGANIZATION
  }
];

class Feature {
  constructor() {
    this.ref = getFirestore().collection('features');
    this.features = DEFAULT_FEATURES;
    this.fetched = false;
  }

  fetch() {
    return new Promise(async (resolve, reject) => {
      try {
        const query = await this.ref.doc('default').get();
        this.features = [];
        this.features.push(...query.data().data);
        this.fetched = true;
        resolve(this);
      } catch (error) {
        reject(error);
      }
    });
  }

  isEnabled(feature) {
    const ft = DEFAULT_FEATURES.filter(f => f.value === feature)[0];
    if (!ft) {
      throw `Invalid feature ${feature}`;
    }
    return !ft.disabled;
  }

  sort() {
    this.features.sort((a, b) => {
      return a.id - b.id;
    });
  }

  values(features) {
    const vals = [...features];
    return vals.map(k => k.value);
  }

  get(access) {
    if (access === undefined) {
      throw { message: 'access can not be undefined' };
    }
    if (!Object.values(ACCESS).includes(access)) {
      throw {
        message: `Invalid access ${access}`
      };
    }

    let res = [...this.features];
    if (access === ACCESS.USER) {
      res = res.filter(ft => ft.access === ACCESS.DEFAULT || ft.access === ACCESS.USER);
    } else if (access === ACCESS.ORGANIZATION) {
      res = res.filter(ft => ft.access === ACCESS.DEFAULT || ft.access === ACCESS.ORGANIZATION);
    }

    return res;
  }

  enabled(access) {
    let res = this.get(access).filter(ft => !ft.disabled);
    return res;
  }

  defaultData(access, defaultFeatures) {
    const res = {};
    const features = this.get(access);
    this.values(features).forEach(val => {
      res[val] = true;
    });
    if (defaultFeatures) {
      Object.keys(res).forEach(key => {
        res[key] = !!defaultFeatures[key];
      });
    }
    return res;
  }
}
export default Feature;
