import firebase from 'firebase/app';
import 'firebase/auth';
import { userRef, eventRef } from '../firebase/db';
import { mapGetters } from 'vuex';
import config from '../config';
import Geolocation from '../services/geolocation';
import { v4 as uuid4 } from 'uuid';
import { captureException } from '@sentry/vue';
import { debounce } from 'throttle-debounce';
import { useDocumentHistory } from '../services/documentHistory'
import {adminPermissionRef} from '../services/firestore'

const { addDocHistory } = useDocumentHistory()

const api = config.api();
export default {
  computed: {
    isAuthenticated() {
      return this.$store.getters.auth;
    },
    isAdmin() {
      return this.$store.getters.isAdmin;
    },
    ...mapGetters({
      auth: 'auth'
    })
  },
  methods: {
    currentUser() {
      if (firebase.auth().currentUser) {
        return firebase.auth().currentUser;
      } else {
        return new Promise((resolve, reject) => {
          const unsubscribe = firebase.auth().onAuthStateChanged(user => {
            unsubscribe();
            resolve(user);
          }, reject);
        });
      }
    },
    async getAuthToken() {
      let user = await this.currentUser();
      if (!user) {
        return false;
      }
      let token = await user.getIdTokenResult(true);
      return token;
    },
    async isAdminUser() {
      let token = await this.getAuthToken();
      if (!token) {
        return false;
      }
      let isAdmin = token.claims.admin ? true : false;

      if(isAdmin){
        const email = token.claims.email;
        adminPermissionRef.doc(email).get().then((snap)=>{
          if(snap.data()){
            this.$store.commit("admin/updatePermission", snap.data())
          }
        })
      }

      this.$store.commit('admin', isAdmin);
      return isAdmin;
    },
    async checkAdmin() {
      let isAdmin = await this.isAdminUser();
      if (isAdmin) {
        localStorage.setItem('admin', true);
      } else {
        localStorage.removeItem('admin');
      }
    },
    async checkSolarAdmin() {
      let t = await this.getAuthToken();
      if (!t) {
        return;
      }
      let solarAdmin = !!t.claims.solarAdmin;
      if (solarAdmin) {
        localStorage.setItem('solarAdmin', solarAdmin);
      } else {
        localStorage.removeItem('solarAdmin');
      }
      this.$store.commit('solarAdmin', solarAdmin);
    },
    logout() {
      localStorage.removeItem('uid');
      localStorage.removeItem('session');
      localStorage.removeItem('mapConfig');
      firebase
        .auth()
        .signOut()
        .then(() => {
          localStorage.removeItem('auth');
          localStorage.removeItem('admin');
          localStorage.removeItem('loggedInAccount');
          localStorage.removeItem('selectAccount');
          this.$store.commit('auth', false);
          window.location.href = '/login';
        })
        .catch(error => {
          alert(error.message);
          window.location.href = '/login';
        });
    },
    async updateUser(user) {
      let userData = await userRef.doc(user.uid).get();
      let data = { email: user.email, profile: {} };
      if (userData.exists) {
        data.profile = userData.data();
      }
      this.$store.commit('user', { ...data });
    },
    async updateUserDetail(user, data) {
      await userRef.doc(user).set(data).then(() => {
        addDocHistory({
          id: user,
          data,
          docCollection: userRef.id
        })
      })
    },
    async userProfile() {
      let user = await this.currentUser();
      let profile = await userRef.doc(user.uid).get();
      if (profile.exists) {
        return profile.data();
      } else {
        return null;
      }
    },
    async changeProfileData(data, user) {
      if (!user) {
        user = await this.currentUser();
      }
      let prevData = await this.userProfile(user);
      let newData = { ...prevData };
      newData = { ...newData, ...data };
      newData = JSON.parse(JSON.stringify(newData))

      await userRef.doc(user.uid).update(newData).then(() => {
        const docHistoryData = { id: user.uid, data: newData, docCollection: userRef.id || '' }
        addDocHistory(docHistoryData)
      })

    },
    async deleteUserDoc(uid) {
      await userRef.doc(uid).delete()
    },

    async getMetrics(user) {
      if (!user) {
        user = await this.currentUser();
      }
      let metrics = {};
      let profile = await userRef.doc(user.uid).get();
      if (profile.exists) {
        metrics = profile.data().metrics;
      }
      return metrics;
    },
    increaseCount(value) {
      if (!value) {
        value = 0;
      }
      value++;
      return value;
    },
    decreaseCount(value) {
      if (!value) {
        value = 0;
      }
      value--;
      return value;
    },
    async changeMetric(metric, func, user = null) {
      if (!user) {
        user = await this.currentUser();
      }
      let metrics = await this.getMetrics(user);
      metrics[metric] = func(metrics[metric]);
      await this.changeProfileData({ metrics: metrics }, user);
    },
    async reduceMetrics(data) {
      let metrics = await this.userProfile();
      metrics = metrics.metrics;
      for (let e of Object.keys(data)) {
        if (data[e]) {
          metrics[e] -= data[e];
        }
      }
      this.changeProfileData({ metrics: metrics });
    },
    updateMetrics(params) {
      let defaultParams = {
        parcelId: this.$route.params.id
      };

      const { id, type } = this.$store.state.loggedInAs;
      const organization = type === 'organization' ? id : '';
      defaultParams = {
        ...defaultParams,
        ...params,
        organization
      };

      const url = `${api.metrics}`;
      this.$axios
        .post(url, defaultParams)
        .then(() => { })
        .catch(err => {
          console.warn(err);
        });
    },
    async logEvent(type, data) {
      let user = await this.currentUser();
      if (!user) {
        return;
      }
      if (!data) {
        data = {};
      }
      if (typeof data != 'object') {
        data = {};
      }

      let event = { type: type, ...data };
      event.timestamp = new Date();
      event.user = user.uid;
      event.path = this.$route.fullPath;

      let parcel = this.$route.path.split('/')[1];
      if (parcel == 'parcel' && type != 'navigation') {
        if (!event.parcel) {
          event.parcel = this.$route.params.id;
        }
      }
      let res;
      try {
        res = await eventRef.add(event);
      } catch (error) {
        console.warn('Error logging event', error);
      }

      return res;
    },
    async logNavigation() {
      this.logEvent('navigation');
    },
    loginActivityHandler: debounce(2000, async (axios, autoLogin, geo) => {
      let session = localStorage.getItem('session');

      if (!session) {
        session = uuid4();
        localStorage.setItem('session', session);
      }

      let timezone = new Date().getTimezoneOffset();
      const api = config.api();
      const gps = Geolocation.validatedData(geo);
      const data = { timezone, session, gps, autoLogin };
      try {
        await axios.post(api.login, data);
      } catch (err) {
        captureException(err);
      }
    }),

    updateLoginHistory(autoLogin = true) {
      this.loginActivityHandler(this.$axios, autoLogin, this.$store.state.gps);
    }
  }
};
