<template>
  <div>
    <slot :offline="offline">
      <base-prop-tooltip tooltip="Offline" color="error">
        <v-icon color="error" v-if="offline && iconOffline">{{ iconOffline }}</v-icon>
      </base-prop-tooltip>
      <base-prop-tooltip tooltip="Online" color="primary">
        <v-icon v-if="!offline && iconOnline">{{ iconOnline }}</v-icon>
      </base-prop-tooltip>
    </slot>
  </div>
</template>

<script>
export default {
  props: {
    iconOnline: {
      type: String,
      default: ''
    },
    iconOffline: {
      type: String,
      default: 'mdi-wifi-off'
    }
  },
  data() {
    return {
      lazyValue: false
    };
  },
  computed: {
    offline: {
      get() {
        return this.lazyValue;
      },
      set(value) {
        this.lazyValue = value;
        this.$emit('input', value);
      }
    }
  },
  mounted() {
    this.offline = !window.navigator.onLine;
    window.addEventListener('online', () => (this.offline = false));
    window.addEventListener('offline', () => (this.offline = true));
  }
};
</script>

<style>
</style>
