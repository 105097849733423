import firebase from 'firebase/app'
import 'firebase/firestore'

const firestore = firebase.firestore();

const docHistoryOpts = {
  app: '',
  collection: ''
}

const docHistoryParams = {
    id : '',
    docCollection: '',
    data: '',
    docUserEmail: '',
}

/**
 * 
 * @param {*} firestore 
 * @param {docHistoryOpts} opts 
 * @returns 
 */

export function useDocumentHistory(
  opts
) {

  const db = firestore.collection(opts?.collection || 'document_history')

  /**
   * 
   * @param {docHistoryParams} params
   */
  function addDocHistory({
    id,
    docCollection,
    data,
    docUserEmail
  }) {

    let userEmail = ''
    try {
      userEmail = localStorage.getItem("userEmail") || ''
    }
    catch {
      //
    }

    const docHistoryMetadata = {
      docUpdatedAt: new Date(),
      docId: id,
      docCollection: docCollection,
      docApp: opts?.app || "PeepsPortal",
      docUserEmail: userEmail || docUserEmail || "",
    };

    const dataFormatted = {
      ...(data || {}),
      ...docHistoryMetadata,
      docHistoryMetadata,
    };

    db.add(dataFormatted)
      .then(() => {
        console.log("doc history added", dataFormatted);
      })
      .catch((err) => {
        console.error(err);
      });
  }

  // async function list(id: string) {
  //   const snap = await getDocs(query(db, where('docId', '==', id)))
  //   const res = [] as any[]
  //   snap.forEach(i => {
  //     res.push({ id: i.id, data: i.data() })
  //   });

  //   res.sort((a: any, b: any) => {
  //     const t = (d: any) => d.data.docUpdatedAt.toDate().getTime()
  //     try {
  //       return t(b) - t(a);
  //     }
  //     catch {
  //       return 0
  //     }
  //   })

  //   return res;
  // }

  // async function restoreVersion(id: string) {
  //   const collectionFactory = (col: string) => {
  //     return col ? collection(firestore, col) : db;
  //   }
  //   const getData = async (col: string, id: string) => {
  //     const dbCol = collectionFactory(col)
  //     const data = await getDoc(doc(dbCol, id))
  //     return {
  //       ...data.data()
  //     }
  //   }

  //   const versionData = await getData('', id) as DocHistory

  //   const docActivity = versionData.docActivity || [];
  //   docActivity.push({
  //     appliedAt: new Date()
  //   })

  //   const newData = { ...versionData, versionId: id }

  //   await updateDoc(
  //     doc(
  //       collectionFactory(versionData.docCollection), versionData.docId
  //     ),
  //     newData
  //   )
  //   updateDoc(doc(db, id), { docActivity })
  // }

  return {
    addDocHistory,
    // list,
    // restoreVersion
  };
}
