<template>
  <v-breadcrumbs :items="breadcrumbs" divider="/"></v-breadcrumbs>
</template>

<script>
export default {
  props: {
    currentPage: {
      type: String,
      default: ''
    },
    admin: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      breadcrumbs: []
    };
  },
  watch: {
    currentPage: {
      handler() {
        this.generateBreadcrumbs();
      },
      immediate: true
    }
  },
  methods: {
    getPath(arr, i) {
      return arr.slice(0, i + 1).join('/');
    },
    generateBreadcrumbs() {
      const pathAdminFrom = localStorage.getItem('fromPathAdmin') || '/';
      const path = String(this.$route.path).split('/');
      this.breadcrumbs = [];
      const { startCase } = require('lodash');
      path.forEach((p, i) => {
        const breadcrumb = {
          text: p
            .split('-')
            .map(e => startCase(e))
            .join(' '),
          disabled: false,
          exact: true,
          to: this.getPath(path, i)
        };
        if (i === 0) {
          breadcrumb.text = 'Home';
          if (this.admin) {
            breadcrumb.to = pathAdminFrom;
          } else {
            breadcrumb.to = '/';
          }
        }
        if (i === path.length - 1) {
          breadcrumb.disabled = true;
        }
        this.breadcrumbs.push(breadcrumb);
      });

      if (this.currentPage) {
        const data = {
          to: this.currentPage,
          text: this.currentPage,
          exact: true,
          disabled: true
        };
        this.breadcrumbs[this.breadcrumbs.length - 1] = data;
      }
    }
  }
};
</script>

<style>
</style>
