<template>
  <v-row v-bind="$attrs">
    <v-col v-for="(item, index) in items" v-bind="getAttrs(item)" :key="index" class="my-0 py-0">
      <slot name="item" :item="item" :attrs="getAttrs(item)" />
    </v-col>
  </v-row>
</template>

<script>
import { addOffsets } from '../../../utils';
export default {
  props: {
    items: {
      type: Array,
      required: true
    }
  },
  methods: {
    getAttrs(item) {
      let res = {};
      if (item && item.cols) {
        res = { ...res, ...item.cols };
      }
      if (item && item.cols.xs) {
        res.cols = item.cols.xs;
      }
      if (item && item.offsets) {
        res = addOffsets(res, item.offsets);
      }
      return res;
    }
  }
};
</script>

<style>
</style>
