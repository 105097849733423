import GalleryIcons from './Gallery';

import Oil from './Oil';
import Propane from './Propane';
import Wood from './Wood';
import SepticTank from './SepticTank';
import Electric from './Electric';
import Solar from './Solar';
import Distance from './Distance';
import DownloadCsv from './DownloadCsv.vue';
import Compass from './Compass.vue';
import SegmentRefresh from './SegmentRefresh.vue';
import PhotoStation from './PhotoStation.vue';
import PhotoStationPrimary from './PhotoStationPrimary.vue';
const Icons = {
  septicTank: {
    component: SepticTank
  },
  oil: {
    component: Oil
  },
  propane: {
    component: Propane
  },
  wood: {
    component: Wood
  },
  electric: {
    component: Electric
  },
  solar: {
    component: Solar
  },
  distance: {
    component: Distance
  },
  downloadCsv: {
    component: DownloadCsv
  },
  compass: {
    component: Compass
  },
  segmentRefresh: {
    component: SegmentRefresh
  },
  photoStation: {
    component: PhotoStation
  },
  photoStationPrimary: {
    component: PhotoStationPrimary
  },
  ...GalleryIcons
};

export default Icons;
