<template>
  <div>
    <slot :on="on" :click="onClick" :cancel="onCancel" :confirm="confirm"></slot>
  </div>
</template>

<script>
export default {
  data() {
    return {
      confirm: false
    };
  },
  computed: {
    on() {
      return {
        ...this.$listeners,
        click: this.onClick
      };
    }
  },
  methods: {
    onCancel() {
      this.confirm = false;
    },
    onClick() {
      if (this.confirm) {
        this.$emit('click');
      } else {
        this.confirm = true;
      }
    }
  }
};
</script>

<style>
</style>