const DEFAULT_PERMISSIONS = ['read', 'update', 'create', 'delete', 'addMember', 'editPhotoGps'];

const PERMISSIONS = {
  read: {
    text: "Read",
    color: "primary"
  },
  update: {
    text: "Update",
    color: "info"
  },
  create: {
    text: "Create",
    color: "success"
  },
  delete: {
    text: "Delete",
    color: "error"
  },
  addMember: {
    text: "Add member",
    color: 'purple'
  },
  editPhotoGps: {
    text: "Edit photo GPS",
    color: 'orange'
  }
}

const updatePermission = ({ email, permissions, data, fullName }) => {
  if (!email) {
    throw 'Email is required';
  }
  if (!permissions) {
    throw 'Permissions is required';
  }
  if (!data) {
    throw 'Permission data is required';
  }
  const res = { ...data };

  if (!res.users) {
    res.users = [];
  }

  DEFAULT_PERMISSIONS.forEach(perm => {
    if (!res[perm]) {
      res[perm] = [];
    }

    if (permissions.includes(perm)) {
      if (!res[perm].includes(email)) {
        res[perm].push(email);
      }
    } else {
      const index = res[perm].indexOf(email);
      if (index > -1) {
        res[perm].splice(index, 1);
      }
    }
  });
  const userIndex = res.users.indexOf(email);
  if (userIndex === -1) {
    res.users.push(email);
  }

  if (!res.fullName) {
    res.fullName = {};
  }
  res.fullName[email] = fullName || '';

  return res;
};
const removePermissions = ({ email, data }) => {
  let res = { ...data };
  res = updatePermission({ email, permissions: [], data });
  const index = res.users.indexOf(email);
  if (index > -1) {
    res.users.splice(index, 1);
  }
  if (!res.fullName) {
    res.fullName = {};
  }
  delete res.fullName[email];
  return res;
};

const getPermissions = ({ email, data }) => {
  const res = { ...data };
  const perms = [];
  DEFAULT_PERMISSIONS.forEach(perm => {
    if (!res[perm]) {
      res[perm] = [];
    }

    if (res[perm].includes(email)) {
      perms.push(perm);
    }
  });
  return perms;
};

const assignTeam = ({ email, team, data }) => {
  const res = { ...data };
  if (!res.team) {
    res.team = {};
  }
  res.team[email] = team || null;
  return res;
};

const updateUserDetail = ({ data, email, detail }) => {
  const res = { ...data };
  if (!res.detail || !res.detail[email]) {
    if (!res.detail) {
      res.detail = {};
    }
    res.detail[email] = {};
  }
  res.detail[email] = { ...res.detail[email], ...detail };
  return res;
};

const getTeam = ({ email, data }) => {
  if (data.team && data.team[email]) {
    return data.team[email];
  }
  return '';
};

const removeTeam = ({ email, data }) => {
  const res = { ...data };
  if (res.team && typeof res.team === 'object') {
    delete res.team[email];
  }
  return res;
};

const removeUserDetail = ({ email, data }) => {
  const res = { ...data };
  if (res.detail && typeof res.detail === 'object' && res.detail[email]) {
    delete res.detail[email];
  }
  return res;
};

export {
  updatePermission,
  removePermissions,
  getPermissions,
  getTeam,
  assignTeam,
  removeTeam,
  updateUserDetail,
  removeUserDetail,
  PERMISSIONS
};
