import AppHeader from '@/components/AppHeader';
import AppFooter from '@/components/AppFooter';

const routes = [
  {
    path: '/about',
    name: 'About',
    components: {
      header: AppHeader,
      default: () => import(/* webpackChunkName: "About" */ '@/views/About/Index'),
      footer: AppFooter
    }
  },
  {
    path: '/about/peeps',
    name: 'FeaturePeeps',
    components: {
      header: AppHeader,
      default: () => import(/* webpackChunkName: "AboutPeeps" */ '@/views/About/Peeps'),
      footer: AppFooter
    }
  },

  {
    path: '/features',
    name: 'Features',
    components: {
      header: AppHeader,
      default: () => import(/* webpackChunkName: "Features" */ '@/views/Features'),
      footer: AppFooter
    }
  },
  {
    path: '/features/tax',
    name: 'FeatureTax',
    components: {
      header: AppHeader,
      default: () => import(/* webpackChunkName: "AboutTax" */ '@/views/About/Tax'),
      footer: AppFooter
    }
  },
  {
    path: '/features/hvac',
    name: 'FeatureHVAC',
    components: {
      header: AppHeader,
      default: () => import(/* webpackChunkName: "AboutHVAC" */ '@/views/About/HVAC'),
      footer: AppFooter
    }
  },
  {
    path: '/features/water',
    name: 'FeatureWater',
    components: {
      header: AppHeader,
      default: () => import(/* webpackChunkName: "AboutWater" */ '@/views/About/Water'),
      footer: AppFooter
    }
  },

  {
    path: '/features/septic',
    name: 'FeatureSeptic',
    components: {
      header: AppHeader,
      default: () => import(/* webpackChunkName: "AboutSeptic" */ '@/views/About/Septic'),
      footer: AppFooter
    }
  },
  {
    path: '/features/solar',
    name: 'AboutSolar',
    components: {
      header: AppHeader,
      default: () => import(/* webpackChunkName: "AboutSolar" */ '@/views/About/Solar'),
      footer: AppFooter
    }
  },
  // {
  //   path: '/features/land',
  //   name: 'AboutLand',
  //   components: {
  //     header: AppHeader,
  //     default: AboutLand,
  //     footer: AppFooter
  //   }
  // }

  {
    path: '/about/privacy',
    name: 'AboutPrivacy',
    components: {
      header: AppHeader,
      default: () => import(/* webpackChunkName: "AboutPrivacy" */ '@/views/About/Privacy'),
      footer: AppFooter
    }
  },
  {
    path: '/explore-nitrate',
    name: 'ExploreNitrate',
    components: {
      header: AppHeader,
      default: () => import(/* webpackChunkName: "ExploreNitrate" */ '@/views/ExploreNitrate'),
      footer: AppFooter
    }
  },
  { path: '/nitrate', redirect: '/explore-nitrate' },
  { path: '/nitrates', redirect: '/explore-nitrate' }
];
export default routes;
