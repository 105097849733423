import { firestore } from './firestore';
const FEATURES = {
  tax: 'tax record',
  taxes: 'tax record',
  water: 'water test',
  septic: 'septic maintenance',
  hvac: 'energy delivery',
  solar: 'solar system information',
  land: 'land activity',
  parcel: 'parcel',
  water_information: 'water information',
  septic_information: 'septic information',
  hvac_information: 'energy information',
  land_information: 'land information',
  solar_database_check: 'check solar database'
};
const ACTIONS = {
  create: 'created',
  update: 'updated',
  delete: 'deleted'
};
const ADD_RECORD = ['tax', 'taxes', 'water', 'septic', 'solar', 'land'];

const EXCLUDE_ACTION = ['solar_database_check'];

class Activity {
  constructor(data) {
    this.ref = firestore.collection('events');
    this.feature = null;
    this.action = null;
    this.detail = null;
    this.user = null;
    this.organization = null;
    this.parcel = null;
    this.set(data);
  }
  static capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  set({ parcel, feature, type, action, detail, user, organization } = {}) {
    this.parcel = parcel || this.parcel;
    this.feature = type || feature || this.feature;
    this.user = user || this.user;
    this.action = action || this.action;
    this.detail = detail || this.detail;
    this.organization = organization || this.organization;
  }

  format() {
    let action;
    if (!this.feature) return;

    if (ADD_RECORD.includes(this.feature) && this.action === 'create') {
      action = 'added';
    } else {
      action = ACTIONS[this.action];
    }

    if (EXCLUDE_ACTION.includes(this.feature)) {
      action = '';
    } else {
      action += ' ';
    }

    let res = ``;

    if (this.feature === 'land') {
      if (this.detail && this.detail.type && this.action === 'create') {
        res += `Land: ${Activity.capitalizeFirstLetter(this.detail.type)}`;
      } else {
        res += `${action}${FEATURES[this.feature]}`;
        if (this.detail && this.detail.type) {
          res += `: ${Activity.capitalizeFirstLetter(this.detail.type)}`;
        }
      }
    } else {
      res += `${action}${FEATURES[this.feature]}`;
    }

    return Activity.capitalizeFirstLetter(res);
  }

  list({ user, feature, limit, organization } = {}) {
    return new Promise((resolve, reject) => {
      let q = this.ref;

      if (user) {
        q = q.where('user', '==', user);
      }

      if (feature) {
        q = q.where('type', '==', feature);
      }

      if (organization) {
        q = q.where('organization', '==', organization);
      }

      if (limit) {
        q = q.limit(limit);
      }

      q.get()
        .then(res => {
          if (res.size) {
            const data = [];
            res.forEach(doc => {
              data.push({ id: doc.id, data: doc.data() });
            });
            resolve(data);
          } else {
            reject(new Error('No recent activity found'));
          }
        })
        .catch(error => {
          reject(error);
        });
    });
  }
}
export default Activity;
