import { parseJson, sort } from "../utils";
import { CMS } from "./cms";

export default class LinkedGroup {
    constructor() {
        this.cms = new CMS()
        this.linkedGroups = null
        this.geometries = null
    }
    setLinkedGroups(data) {
        this.linkedGroups = data
    }
    setGeometries(data) {
        this.geometries = data
    }
    /**
     * 
     * @param {string} group 
     * @returns {string}
     */
    static group(group) {
        return (group.split("/")[0] || '').trim()
    }
    static compare(a, b) {
        return LinkedGroup.group(a).toLowerCase().trim() === LinkedGroup.group(b).toLowerCase().trim()
    }
    getLinkedGroups() {
        return new Promise((resolve, reject) => {
            this.cms.detail(null, 'LinkedGroups').then(res => {
                let data = parseJson(res?.data?.data, {})?.linkedGroups || []
                data = data.map(i => {
                    const splitted = String(i).split("/")
                    const res = {
                        value: '',
                        admin: false
                    }
                    res.value = splitted[0]
                    if (splitted.length > 1 && splitted[1] === 'admin') {
                        res.admin = true
                    }
                    return res
                })
                this.linkedGroups = data;
                resolve(data)
            }).catch(err => reject(err))
        })
    }
    async listGeometries() {
        if (!this.linkedGroups) {
            throw new Error("Linked groups are not fetched")
        }
        let data = [];
        try {
            const res = await this.cms.get({ type: 'geojson' });
            res.forEach(doc => {
                const detail = doc.data();
                const widgetData = { id: doc.id, data: detail, visible: false, admin: false };
                let isLinkedGroup = false;

                try {
                    const splitted = String(widgetData.data.group || '').split('/');
                    const group = String(splitted[0] || '')
                        .toLowerCase()
                        .trim();
                    const groupType = String(splitted[1] || '')
                        .toLowerCase()
                        .trim();
                    const linkedGroup = this.linkedGroups.filter(
                        i =>
                            String(i.value || '')
                                .toLowerCase()
                                .trim() === group
                    )[0];
                    if (linkedGroup) {
                        isLinkedGroup = true;
                        widgetData.admin = linkedGroup?.admin && groupType === 'admin';
                        data.push(widgetData)
                    }
                } catch (error) {
                    console.warn(error);
                    //
                }
            });
        }
        catch (error) {
            console.error(error)
        }
        data = sort({ items: data, field: 'data.key' });
        this.geometries = data;
        return data
    }
}