import LinkedGroup from '../../services/linkedGroup';
import User from '../../services/user';
import { parseJson } from '../../utils';
import { PARCEL_DEFAULT_FILTER } from '../../utils/parcelFilter';

const user = new User();

const defaultMapConfig = PARCEL_DEFAULT_FILTER;
//   team: '',
//   acres: true,
//   scale: true,
//   reference: false,
//   tct: false,
//   tot: false,
//   hash: '',
//   style: DEFAULT_MAP_STYLE,
//   showParcelId: true,
//   monitoringDueAny: true,
//   monitoringDue: '',
//   monitoringDueMissing: false,
//   coordinates: null,
//   boundary: false,
//   teamLayer: false,
//   land: '',
//   landPreserve: '',
//   showPreserve: 'selected',
//   monitoringColor: true
// };

let foundMapConfig = false;
let mapConfig = localStorage.getItem('mapConfig');
if (mapConfig) {
  try {
    mapConfig = { ...defaultMapConfig, ...JSON.parse(mapConfig) };
    foundMapConfig = true;
  } catch (error) {
    mapConfig = defaultMapConfig;
  }
} else {
  mapConfig = defaultMapConfig;
}

const getLocal = key => {
  let item = localStorage.getItem(key);
  if (!item) {
    return null;
  }
  try {
    return JSON.parse(item);
  } catch {
    return null;
  }
};

const searchQuery = localStorage.getItem('searchQuery') || '';
const searchDialog = parseJson(localStorage.getItem('searchDialog'), false);
const geoscriptDialog = parseJson(localStorage.getItem('geoscriptDialog'), false);
const viewMode = localStorage.getItem('viewMode');

export default {
  state: {
    parcels: [],
    tctParcels: [],
    totParcels: [],
    totParcelsAll: [],
    taxCount: [],
    waterTestCount: [],
    parcelTypes: {
      data: [
        { value: 'resPrimary', text: 'Primary Residence' },
        { text: 'Part-Time Residence', value: 'resPartTime' },
        { text: 'Rental Property', value: 'rentalProperty' },
        { text: 'Buildable Lot', value: 'lotBuildable' },
        { text: 'Wetland', value: 'lotWetland' },
        { value: 'openSpace', text: 'Open Space' },
        { value: 'commercial', text: 'Commercial' },
        { text: 'Other', value: 'other' }
      ]
    },
    parcelLoading: true,
    disableCreateParcel: false,
    latestParcel: null,
    viewMode: viewMode || '',
    filterTeam: '',
    map: null,
    mapConfig: { ...mapConfig },
    foundMapConfig,
    gallerySetting: {
      showDate: false
    },
    loading: {
      addParcel: false
    },
    teamGeoJSON: null,
    searchDialog: searchDialog,
    geoscriptDialog: geoscriptDialog,
    searchQuery: searchQuery === 'null' ? '' : searchQuery,
    visibleLayers: [],
    detail: {
      dialog: false,
      map: false,
      id: '',
      data: null
    },
    linkedGroups: getLocal('linkedGroups') || [],
    linkedGroupsGeometries: []
  },
  getters: {
    parcels(state) {
      state.parcels.map((e, i) => {
        e.index = i;
        return e;
      });
      return state.parcels;
    },
    taxCount(state) {
      return state.taxCount;
    },
    waterTestCount(state) {
      return state.waterTestCount;
    },
    parcelTypes(state) {
      return state.parcelTypes;
    },
    parcelLoading(state) {
      return state.parcelLoading;
    },
    latestParcel(state) {
      return state.latestParcel;
    },
    viewMode(state) {
      return state.viewMode;
    },
    filterTeam(state) {
      return state.filterTeam;
    },
    map(state) {
      return state.map;
    },

    loading(state) {
      return state.loading;
    },
    disableCreateParcel(state) {
      return state.disableCreateParcel;
    },
    filter(state) {
      return state.mapConfig;
    },
    teamFilter(state) {
      return state.mapConfig.team;
    },
    acresFilter(state) {
      return state.mapConfig.acres;
    },
    showParcelIdFilter(state) {
      return state.mapConfig.showParcelId;
    },
    monitoringDue(state) {
      return state.mapConfig.monitoringDue;
    },
    monitoringDueAny(state) {
      return state.mapConfig.monitoringDueAny;
    },
    monitoringDueMissing(state) {
      return state.mapConfig.monitoringDueMissing;
    },
    scaleFilter(state) {
      return state.mapConfig.scale;
    },
    mapStyle(state) {
      return state.mapConfig.style;
    },
    mapConfig(state) {
      return state.mapConfig;
    },
    gallerySetting(state) {
      return state.gallerySetting;
    }
  },
  mutations: {
    setParcels(state, data) {
      state.parcel = [];
      state.parcels = data;
      // localStorage.setItem('parcels', JSON.stringify(data));
    },
    setTaxCount(state, data) {
      let found = false;
      state.taxCount.forEach((e, index) => {
        if (e.parcel == data.parcel) {
          state.taxCount.splice(index, 1, e);
        }
      });
      if (!found) {
        state.taxCount.push(data);
      }
    },
    setWaterTestCount(state, data) {
      let found = false;
      state.waterTestCount.forEach((e, index) => {
        if (e.parcel == data.parcel) {
          state.waterTestCount.splice(index, 1, e);
        }
      });
      if (!found) {
        state.waterTestCount.push(data);
      }
    },
    parcelTypes(state, data) {
      state.parcelTypes = data;
    },
    parcelLoading(state, data) {
      state.parcelLoading = data;
    },
    latestParcel(state, data) {
      state.latestParcel = data;
    },
    viewMode(state, data) {
      state.viewMode = data;
      localStorage.setItem('viewMode', viewMode);
    },
    setLoading(state, { key, value }) {
      state.loading[key] = value;
    },
    setDisableCreateParcel(state, val) {
      state.disableCreateParcel = val;
    },
    setMap(state, map) {
      state.map = map;
    },
    setMapStyle(state, style) {
      state.mapConfig.style = style;
    },
    setFilter(state, { key, value }) {
      state.mapConfig[key] = value;
    },
    setMapConfigObj(state, config) {
      state.mapConfig = { ...state.mapConfig, ...config };
      localStorage.setItem('mapConfig', JSON.stringify(state.mapConfig));
    },
    setMapConfig(state, { key, value }) {
      state.mapConfig[key] = value;
      localStorage.setItem('mapConfig', JSON.stringify(state.mapConfig));
    },
    updateGallerySettingObj(state, obj) {
      state.gallerySetting = obj;
    },
    updateGallerySetting(state, { key, value }) {
      state.gallerySetting[key] = value;
    },
    setTctParcels(state, parcels) {
      state.tctParcels = parcels;
    },
    setTotParcels(state, parcels) {
      state.totParcels = parcels;
    },
    setTotParcelsAll(state, parcels) {
      state.totParcelsAll = parcels;
    },
    teamGeoJSON(state, geojson) {
      state.teamGeoJSON = geojson;
    },
    setSearchDialog(state, value) {
      state.searchDialog = value;
      localStorage.setItem('searchDialog', JSON.stringify(value));
    },
    setGeoscriptDialog(state, value) {
      state.geoscriptDialog = value;
      localStorage.setItem('geoscriptDialog', JSON.stringify(value));
    },
    setSearchQuery(state, value) {
      state.searchQuery = value;
      localStorage.setItem('searchQuery', value);
    },
    resetMapConfig(state) {
      state.mapConfig = defaultMapConfig;
    },
    setVisibleLayers(state, value) {
      state.visibleLayers = value;
    },
    resetParcelDetail(state) {
      state.detail.dialog = false;
      state.detail.map = false;
      state.detail.id = '';
      state.detail.data = null;
    },
    setParcelDetail(state, { key, value }) {
      state.detail[key] = value;
    },
    setLinkedGroups(state, value) {
      state.linkedGroups = value
      localStorage.setItem("linkedGroups", JSON.stringify(value))
    },
    setLinkedGroupsGeometries(state, value) {
      state.linkedGroupsGeometries = value
    }
  },
  actions: {
    updateGallerySettingObj({ commit }, obj) {
      commit('updateGallerySettingObj', obj);
    },
    updateGallerySetting({ commit, state, rootState }, data) {
      commit('updateGallerySetting', data);
      user.updateProfile(rootState.user.uid, { peepsGallerySetting: state.gallerySetting });
    },
    resetMapConfig({ commit }) {
      commit('setMapConfigObj', defaultMapConfig);
    },
    resetParcelFilter({ commit }) {
      commit('setMapConfigObj', defaultMapConfig);
    },
    fetchLinkedGroups({ commit, rootGetters }) {
      const lg = new LinkedGroup();
      lg.getLinkedGroups().then(data => {
        commit("setLinkedGroups", data)
        lg.listGeometries().then(geometries => {
          commit("setLinkedGroupsGeometries", (geometries || []).filter(i => {
            if (i.admin) {
              return rootGetters.isAdmin === true
            }
            return true
          }))
        }).catch(err => {
          console.error(err)
        })
      })
    },
  }
};
