<template>
  <div>
    <div>
      <slot name="button">
        <v-btn
          v-if="!showAlways"
          color="primary"
          text
          class="text-capitalize ml-1"
          :class="value ? 'v-btn--active' : ''"
          @click="value = !value"
        >
          <slot name="title"> </slot>
          <slot name="icon">
            <v-icon>
              {{ value ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
            </v-icon>
          </slot>
        </v-btn>
        <slot name="action-after" :value="value"></slot>
      </slot>
    </div>
    <v-slide-y-transition>
      <div v-show="value || showAlways">
        <slot></slot>
      </div>
    </v-slide-y-transition>
  </div>
</template>

<script>
export default {
  props: {
    showAlways: {
      type: Boolean,
      default: false
    },
    show: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      value: this.show || false
    };
  }
};
</script>

<style>
</style>
