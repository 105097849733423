<template>
  <div class="b-dialog-container" ref="dialogContainer" :style="stylesComputed">
    <div :class="{ 'sticky-header': stickyHeader }">
      <div class="text-right pr-1">
        <slot name="action-before"></slot>
        <slot name="close">
          <v-btn style="z-index: 9" @click="$emit('input', false)" class="btn-close" icon>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </slot>
        <slot name="action-after"></slot>
      </div>
    </div>
    <slot name="default"></slot>
  </div>
</template>

<script>
export default {
  props: {
    stickyHeader: {
      type: Boolean,
      default: false
    },
    transparent: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    stylesComputed() {
      let style = ``;
      if (!this.transparent) {
        style += `background-color: #fff !important;`;
      }
      return style;
    },
    screenWidth() {
      return this.$vuetify.breakpoint.width;
    }
  },

  watch: {
    screenWidth: {
      handler: 'setDialogHeight'
    }
  },

  mounted() {
    this.setDialogHeight();
  },
  methods: {
    onClickEsc() {
      if (this.searchDialog) {
        this.searchQuery = '';
      }
    },
    setDialogHeight() {
      const screenHeight = window.innerHeight;
      const screenWidth = window.innerWidth;
      const appbarHeight = 56;
      const parcelTitleHeight = {
        lg: 73,
        sm: 69
      };
      const width = screen => {
        if (screen < 400) {
          return 250;
        } else if (screen < 600) {
          return 300;
        } else {
          return 350;
        }
      };
      const screen = screenWidth > 450 ? 'lg' : 'sm';
      const dialogHeight = screenHeight - appbarHeight - parcelTitleHeight[screen];
      const el = this.$refs.dialogContainer;
      if (el) {
        el.style.height = dialogHeight - 5 + 'px';
        el.style.width = width(screenWidth) + 'px';
      }
    }
  }
};
</script>

<style lang="scss">
@import '@/design/_colors.scss';
.b-dialog-container {
  position: fixed;
  bottom: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.839);
  overflow-y: scroll;
  overflow-x: hidden;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;

  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.5);
    box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }
}
// .btn-close-container {
//   position: sticky;
//   top: 0;
//   z-index: 999;
// }
.sticky-header {
  position: sticky;
  top: 0;
  z-index: 3;
  background-color: white;
}
</style>
