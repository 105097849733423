<template>
  <section>
    <v-app-bar app flat>
      <v-img
        @click="$router.push('/')"
        class="ml-4 mr-4 peeps-image cursor-pointer"
        src="@/assets/img/PeepsBeside.svg"
      ></v-img>
      <v-toolbar-title
        class="peeps--text peeps--font toolbar-title cursor-pointer"
        @click="$router.push('/')"
        >My Peeps Portal</v-toolbar-title
      >
      <v-spacer></v-spacer>
      <div class="d-md-none d-flex flex-column">
        <v-app-bar-nav-icon class="menu-icon" @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
        <span class="menu-text">Menu</span>
      </div>
      <div class="d-md-flex d-none">
        <v-btn
          class="ml-1"
          text
          v-for="(nav, index) in nav_links"
          :key="index"
          :to="nav.to"
          :href="nav.href"
          :color="nav.color || 'primary'"
          >{{ nav.text }} <v-icon v-if="nav.icon">{{ nav.icon }}</v-icon></v-btn
        >
        <screen-size></screen-size>
        <v-btn
          text
          color="primary"
          to="/profile"
          v-if="$store.getters.auth"
          class="text-capitalize"
        >
          <span v-if="user_profile">
            <span class="d-none d-md-inline">
              {{ user_profile.first_name }}
            </span>

            <span class="avatar"> {{ user_profile.first_name.substring(0, 1) }}</span>
          </span>
          <span class="text-capitalize" v-else
            ><v-progress-circular
              color="default"
              indeterminate
              size="20"
              width="3"
            ></v-progress-circular
          ></span>
        </v-btn>
      </div>
    </v-app-bar>
    <v-navigation-drawer right app disable-resize-watcher v-model="drawer">
      <v-list nav>
        <v-list-item class="px-2">
          <v-img src="@/assets/img/PeepsBeside.svg" max-width="50"></v-img>
          <v-toolbar-title class="peeps--text peeps--font" @click="$router.push('/')"
            >My Peeps Portal</v-toolbar-title
          >
        </v-list-item>
        <v-list-item-group color="primary">
          <v-list-item
            v-for="(nav, index) in nav_links"
            :key="index"
            :href="nav.href"
            :to="nav.to"
            :color="nav.color || 'primary'"
          >
            <v-list-item-title
              >{{ nav.text }} <v-icon v-if="nav.icon">{{ nav.icon }}</v-icon></v-list-item-title
            >
          </v-list-item>

          <v-list-item to="/profile" v-if="$store.getters.auth">
            <v-list-item-title>
              <span v-if="user_profile" class="text-capitalize">
                {{ user_profile.first_name }}
                <span class="avatar">
                  {{ user_profile.first_name.substring(0, 1) }}
                </span>
              </span>

              <span class="text-capitalize" v-else
                ><v-progress-circular
                  color="default"
                  indeterminate
                  size="20"
                  width="3"
                ></v-progress-circular
              ></span>
            </v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
  </section>
</template>

<script>
import user from '@/mixins/user';
import config from '../config';
export default {
  data: () => ({
    nav_links: [
      {
        text: 'Dashboard',
        to: '/admin/dashboard',
        icon: 'mdi-view-dashboard'
      },
      {
        text: 'Email',
        to: '/admin/email',
        icon: 'mdi-email'
      },
      {
        text: 'Sandbox',
        to: '/admin/sandbox',
        icon: 'mdi-cube-outline'
      }
    ],
    drawer: false
  }),
  components: {
    ScreenSize: () => import('@/components/Core/ScreenSize.vue')
  },
  mounted() {
    const { type, name } = this.$store.state.loggedInAs;
    const org = type === 'organization' ? name : '';

    let fromPathAdmin = localStorage.getItem('fromPathAdmin') || '';

    if (fromPathAdmin) {
      fromPathAdmin = `${window.location.origin}${fromPathAdmin}`;
    }

    if (config.deployment === 'main') {
      this.nav_links.push({
        text: 'CMS',
        href: `https://cms.peepsportal.org/admin?organization=${org}&pathHome=${fromPathAdmin}`,
        icon: 'mdi-file-document-edit'
      });
    } else if (config.deployment === 'beta') {
      this.nav_links.push({
        text: 'CMS',
        href: `https://beta-cms.peepsportal.org/admin?organization=${org}&pathHome=${fromPathAdmin}`,
        icon: 'mdi-file-document-edit'
      });
    } else {
      this.nav_links.push({
        text: 'CMS',
        href: `https://dev-cms.peepsportal.org/admin?organization=${org}&pathHome=${fromPathAdmin}`,
        icon: 'mdi-file-document-edit'
      });
    }
  },
  mixins: [user],
  computed: {
    user_profile() {
      if (this.$store.getters.user.profile.first_name) {
        return this.$store.getters.user.profile;
      }
      return null;
    },
    md() {
      return this.$vuetify.breakpoint.md;
    }
  },
  watch: {
    md() {
      if (this.md) {
        this.drawer = false;
      }
    }
  }
};
</script>

<style></style>
