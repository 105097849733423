<template>
  <v-btn
    color="primary lighten-2"
    v-bind="$attrs"
    text
    class="ml-1 v-btn--active"
    v-show="reference"
    style="text-transform: none"
  >
    <span class="primary--text">
      {{ reference }}
    </span>
  </v-btn>
</template>

<script>
export default {
  props: {
    reference: {
      type: String,
      default: ''
    }
  }
};
</script>

<style>
</style>
