<template>
  <section>
    <v-app-bar app flat>
      <v-img
        @click="$router.push('/')"
        class="ml-4 mr-4 peeps-image cursor-pointer"
        src="@/assets/img/PeepsBeside.svg"
      ></v-img>
      <v-toolbar-title
        class="peeps--text peeps--font toolbar-title cursor-pointer"
        @click="$router.push('/')"
      >
        <span v-html="appBarTitle"></span>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn color="primary" text to="/login" v-if="!$store.getters.auth" class="d-md-none d-flex"
        >Login</v-btn
      >
      <div class="d-md-none align-center d-flex" v-if="$store.getters.auth">
        <screen-size v-show="!offline"></screen-size>
        <base-offline v-model="offline"></base-offline>
        <base-prop-tooltip tooltip="Logout" color="primary">
          <v-btn color="primary" icon @click="logout()"><v-icon>mdi-logout</v-icon></v-btn>
        </base-prop-tooltip>
        <NavButtonAdmin />
      </div>
      <div class="d-md-none d-flex flex-column">
        <v-app-bar-nav-icon class="menu-icon" @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
        <span class="menu-text">Menu</span>
      </div>
      <div class="d-md-flex d-none align-center">
        <v-btn
          text
          class="text-capitalize"
          v-for="(nav, index) in nav_links"
          :key="index"
          :to="nav.to"
          color="primary"
          v-show="showNavButton(nav)"
        >
          <span v-if="nav.text">
            {{ nav.text }}
          </span>
          <v-icon @click="onClick(nav.action)" v-else-if="nav.icon">{{ nav.icon }}</v-icon>
        </v-btn>
        <screen-size v-if="!offline" class="mr-1"></screen-size>
        <base-offline v-model="offline"></base-offline>
        <v-btn text color="primary" to="/login" v-if="!$store.getters.auth">Login</v-btn>
        <!-- <v-btn text color="primary" to="/signup" v-if="!$store.getters.auth"
          >Sign Up</v-btn
        > -->
        <v-btn
          v-if="$store.getters.auth"
          @click="logout()"
          text
          class="text-capitalize ml-1"
          color="primary"
        >
          <span v-if="$vuetify.breakpoint.lgAndUp"> Logout</span>
          <v-icon>mdi-logout</v-icon>
        </v-btn>
        <NavButtonAdmin />
        <v-menu
          max-height="80vh"
          offset-y
          v-if="loggedInAs && loggedInAs.name && auth"
          transition="slide-y-transition"
        >
          <template #activator="{ on }">
            <v-btn v-on="on" text color="primary" class="text-capitalize">
              <span class="d-none d-md-inline mr-1">
                {{ loggedInAs.name }}
              </span>
              <span class="avatar"> {{ loggedInAs.name.substring(0, 1) }}</span>
            </v-btn>
          </template>

          <v-list nav min-width="200">
            <v-btn
              text
              class="justify-start text-capitalize my-2"
              :class="{ 'v-btn--active': org.id === loggedInAs.id }"
              :color="org.id === loggedInAs.id ? 'primary' : ''"
              block
              large
              v-for="org in organizationsComputed"
              :key="org.id"
              @click="loginAsOrganization(org.id, true)"
            >
              {{ org.name }}
            </v-btn>
            <v-divider class="my-2" v-if="organizations.length"></v-divider>
            <v-btn
              text
              class="justify-start text-capitalize"
              :class="{ 'v-btn--active': loggedInAs.type === 'user' }"
              :color="loggedInAs.type === 'user' ? 'primary' : ''"
              block
              large
              @click="loginAsUser(true)"
            >
              {{ user.profile.first_name + ' ' + user.profile.last_name }}
            </v-btn>
            <v-divider v-if="organizations.length" class="my-2"></v-divider>
            <v-btn
              large
              block
              text
              class="text-capitalize justify-start"
              to="/organizations"
              color="primary"
            >
              <v-icon class="mr-2">mdi-domain</v-icon>
              My Organizations</v-btn
            >
            <v-btn
              large
              block
              text
              class="text-capitalize justify-start"
              to="/profile"
              color="primary"
            >
              <v-icon class="mr-2">mdi-account</v-icon>
              My Account</v-btn
            >
          </v-list>
        </v-menu>
        <v-btn v-if="$store.getters.auth && !loggedInAs.name" text>
          <v-progress-circular
            color="default"
            indeterminate
            size="20"
            width="3"
          ></v-progress-circular>
        </v-btn>
      </div>
    </v-app-bar>
    <v-navigation-drawer right app disable-resize-watcher v-model="drawer">
      <v-list nav>
        <v-list-item-group color="primary">
          <v-list-item
            v-for="(nav, index) in nav_links"
            :key="index"
            :to="nav.to"
            v-show="showNavButton(nav)"
            @click="onClick(nav.action)"
          >
            <v-list-item-title v-if="nav.text">{{ nav.text }}</v-list-item-title>
            <v-icon v-else-if="nav.icon">
              {{ nav.icon }}
            </v-icon>
          </v-list-item>

          <v-menu
            max-height="80vh"
            offset-y
            v-if="loggedInAs && loggedInAs.name && auth"
            transition="slide-y-transition"
          >
            <template #activator="{ on }">
              <v-list-item v-on="on">
                <v-list-item-content>
                  <v-list-item-title>
                    {{ loggedInAs.name }}
                    <span class="avatar"> {{ loggedInAs.name.substring(0, 1) }}</span>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>

            <v-list nav min-width="200">
              <v-btn
                text
                class="justify-start text-capitalize my-2"
                :class="{ 'v-btn--active': loggedInAs.id === org.id }"
                :color="loggedInAs.id === org.id ? 'primary' : ''"
                block
                large
                v-for="org in organizationsComputed"
                :key="org.id"
                @click="loginAsOrganization(org.id, true)"
              >
                {{ org.name }}
              </v-btn>
              <v-divider class="my-2" v-if="organizationsComputed.length"></v-divider>
              <v-btn
                text
                class="justify-start text-capitalize"
                :class="{ 'v-btn--active': loggedInAs.type === 'user' }"
                :color="loggedInAs.type === 'user' ? 'primary' : ''"
                block
                large
                @click="loginAsUser(true)"
              >
                {{ user.profile.first_name + ' ' + user.profile.last_name }}
              </v-btn>
              <v-divider v-if="organizations.length" class="my-2"></v-divider>
              <v-btn
                large
                block
                text
                class="text-capitalize justify-start"
                to="/organizations"
                color="primary"
              >
                <v-icon class="mr-2">mdi-domain</v-icon>
                My Organizations</v-btn
              >
              <v-btn
                large
                block
                text
                class="text-capitalize justify-start"
                to="/profile"
                color="primary"
              >
                <v-icon class="mr-2">mdi-account</v-icon>
                My Account</v-btn
              >
            </v-list>
          </v-menu>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
  </section>
</template>

<script>
import { mapState } from 'vuex';
import accMixin from '../mixins/account';
import { CMS } from '../services/cms';
export default {
  mixins: [accMixin],
  data: () => ({
    navGroup: null,
    nav_links: [
      { text: 'Home', to: '/', cms: 'Nav_Page_Home' },
      { text: 'Features', to: '/features' },
      { text: 'Parcels', to: '/parcels' },
      { text: '', action: 'parcelPageSearch', icon: 'mdi-magnify' },
      { text: 'About', to: '/about', cms: 'Nav_Page_About' },
      { text: 'Help', to: '/help', cms: 'Nav_Page_Help' }
    ],
    cmsPages: ['Nav_Page_Home', 'Nav_Page_About', 'Nav_Page_Help'],
    offline: false
  }),
  components: {
    ScreenSize: () => import('@/components/Core/ScreenSize.vue'),
    NavButtonAdmin: () => import('./NavButtonAdmin.vue')
  },
  computed: {
    ...mapState(['organizations', 'user', 'loggedInAs', 'auth', 'appBarTitle']),
    drawer: {
      get() {
        return this.$store.state.drawer;
      },
      set(value) {
        this.$store.commit('drawer', value);
      }
    },
    organizationsComputed() {
      return this.organizations;
      // return this.organizations.filter(org => org.id !== this.loggedInAs.id);
    },
    user_profile() {
      if (this.$store.getters.user.profile.first_name) {
        return this.$store.getters.user.profile;
      }
      return null;
    },
    md() {
      return this.$vuetify.breakpoint.md;
    }
  },
  watch: {
    md() {
      if (this.md) {
        this.drawer = false;
      }
    }
  },
  mounted() {
    setTimeout(() => {
      this.checkCmsPages();
    }, 1000);
  },
  methods: {
    onClick(action) {
      if (action === 'parcelPageSearch') {
        let url = `/parcels`;
        const viewMode = this.$store.state.parcel.viewMode;
        if (['list', 'map'].includes(viewMode)) {
          url += `/${viewMode}`;
        }
        this.$store.commit('setSearchDialog', true);
        if (this.$route.path !== url) {
          this.$router.push(url);
        }
      }
    },
    checkCmsPage(key) {
      const cms = new CMS();
      cms
        .exists(key)
        .then(() => {
          this.$store.commit('cmsPages', { page: key, value: true });
        })
        .catch(() => {
          this.$store.commit('cmsPages', { page: key, value: false });
        });
    },
    checkCmsPages() {
      this.cmsPages.forEach(pageKey => {
        this.checkCmsPage(pageKey);
      });
    },
    showNavButton(btn) {
      if (this.loggedInAs?.type === 'organization') {
        if (btn.cms) {
          return this.$store.state.cmsPages[btn?.cms];
        }
      }
      return true;
    }
  }
};
</script>

<style scoped lang="scss">
.v-btn {
  text-transform: capitalize !important;
}
</style>
