import colors from '../../design/_colors.scss';
export default {
  state: {
    featuresList: [
      {
        feature: 'water',
        text: 'Water',
        url: 'water',
        style: {
          color: colors['feature-water'],
          dark: true,
          icon: 'mdi-water'
        },
        about: {
          text: 'Learn more about',
          value: 'water',
          url: '/features/water'
        }
      },
      {
        feature: 'septic',
        text: 'Septic',
        url: 'septic',
        style: {
          color: colors['feature-septic'],
          dark: true,
          icon: '$septicTank',
          class: ['mr-1']
        },
        about: {
          text: 'Learn more about',
          value: 'septic systems',
          url: '/features/septic'
        }
      },
      {
        feature: 'hvac',
        text: 'Energy',
        url: 'hvac',
        style: {
          dark: true,
          color: colors['feature-hvac'],
          icon: 'mdi-hvac'
        },
        about: {
          text: 'Learn more about',
          value: 'Energy',
          url: '/features/hvac'
        }
      },
      {
        feature: 'solar',
        text: 'Solar',
        url: 'solar',
        style: {
          dark: true,
          color: colors['feature-solar'],
          icon: '$solar'
        },
        about: {
          text: 'Learn more about',
          value: 'Solar',
          url: '/features/solar'
        }
      },
      {
        feature: 'tax',
        text: 'Taxes',
        url: 'taxes',
        style: {
          color: colors['feature-tax'],
          dark: true,
          icon: 'mdi-currency-usd'
        },
        about: {
          text: 'Learn more about',
          value: 'taxes',
          url: '/features/tax'
        }
      },
      {
        feature: 'land',
        text: 'Land',
        url: 'land',
        style: {
          color: colors['feature-land'],
          colorLight: colors['feature-land-light'],
          dark: true,
          icon: 'mdi-map-outline'
        },
        about: {
          text: 'Learn more about',
          value: 'land',
          url: '/features/land'
        }
      }
    ]
  },
  getters: {
    featuresList(state) {
      return state.featuresList;
    }
  }
};
