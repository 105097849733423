import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';

import '@/plugins/vuemeta';
import '@/plugins/components';

import './plugins/firebase';
import '@/assets/css/main.css';
import '@/assets/css/main.scss';
import '@/assets/css/card.sass';
import '@/plugins/axios';
import commonMixins from '@/plugins/common_mixins';
import config from './config';

import initializeLogRocket from './logrocket';
initializeLogRocket();

Vue.use(commonMixins);

Vue.config.productionTip = false;

Vue.prototype.$bus = new Vue();

const enableSentry = () => {
  if (config.sentry()) {
    const Sentry = require('@sentry/vue');
    const { BrowserTracing } = require('@sentry/tracing');

    Sentry.init({
      Vue,
      dsn: config.sentry(),
      integrations: [
        new BrowserTracing({
          routingInstrumentation: Sentry.vueRouterInstrumentation(router),
          tracingOrigins: [
            'localhost',
            'peepsportal.org',
            'beta.peepsportal.org',
            'dev.peepsportal.org',
            /^\//
          ]
        })
      ],
      tracesSampleRate: 1.0
    });
  }
};

setTimeout(() => {
  enableSentry();
}, 3000);

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app');
