import Organization from '../services/organization';
import appMixin from './app';
import { getPermissions } from '../utils/organization';
import { CMS } from '../services/cms';
import { parcelRef } from '../firebase/db';
import { sort } from '../utils';

const PERMISSIONS = ['create', 'read', 'update', 'delete', 'addMember', 'editPhotoGps'];
const org = new Organization();

export default {
  mixins: [appMixin],
  methods: {
    buildParcelQuery(uid) {
      if (!uid) {
        uid = this.$store.state.user.uid;
      }
      const { type, id } = this.$store.state.loggedInAs;

      let q = parcelRef;

      if (type === 'organization') {
        q = q.where('user', '==', id);
      } else {
        q = q.where('user', '==', uid);
      }
      return q;
    },

    async getParcels(uid) {
      this.$store.commit('parcelLoading', true);
      const q = this.buildParcelQuery(uid);
      try {
        const result = await q.get();
        let data = [];
        result.forEach(item => {
          data.push({ ...item.data(), id: item.id });
        });
        data = sort({ items: data, field: 'number' });
        this.$store.commit('setParcels', [...data]);
      } catch (error) {
        this.$store.commit('setParcels', []);
        console.warn('error getting parcels', error);
      }
      this.$store.commit('parcelLoading', false);
    },

    checkLoggedInAccount() {
      try {
        const loggedInAs = JSON.parse(localStorage.getItem('loggedInAccount'));
        const { type, id } = loggedInAs;
        if (type === 'user') {
          this.loginAsUser();
        } else if (type === 'organization') {
          this.loginAsOrganization(id);
        }
      } catch {
        this.loginAsUser();
      }
    },
    allowAllPermission() {
      PERMISSIONS.forEach(permission => {
        this.$store.commit('updatePermission', { permission, value: true });
      });
    },
    getOrgFeatures(id) {
      org
        .retrieve(id)
        .then(res => {
          if (res.exists) {
            const { features } = res.data();
            if (features) {
              this.$store.commit('setOrganizationFeatures', features);
            }
          } else {
            this.$store.commit('resetOrgFeatures');
          }
        })
        .catch(() => {
          this.$store.commit('resetOrgFeatures');
        });
    },
    getOrgPermissions() {
      const { type, id } = this.$store.state.loggedInAs;
      if (type !== 'organization') {
        this.allowAllPermission();
        return;
      }
      this.getOrgFeatures(id);
      const user = this.$store.state.user.email;
      const orgDetail = this.$store.state.organizations.filter(o => o.id === id)[0];
      if (!orgDetail) {
        return;
      }
      if (!orgDetail.added) {
        this.allowAllPermission();
        return;
      }
      org.getPermissions(id).then(res => {
        if (res.exists) {
          const permissions = res.data();
          const userPermissions = getPermissions({ email: user, data: permissions });
          if (!userPermissions) {
            throw 'No user permissions object';
          }
          PERMISSIONS.forEach(perm => {
            if (userPermissions.includes(perm)) {
              this.$store.commit('updatePermission', { permission: perm, value: true });
            } else {
              this.$store.commit('updatePermission', { permission: perm, value: false });
            }
          });
        }
      });
    },
    getLandPreserves({ id, type }) {
      this.$store.commit('landPreserves', []);
      if (type === 'organization') {
        const org = new Organization();
        org.landPreserves(id).then(data => {
          if (data) {
            this.$store.commit('landPreserves', data);
          }
        });
      }
    },
    onLogin({ type, id }) {
      this.getLandPreserves({ id, type });
    },
    reset() {
      this.$store.commit('resetMapConfig');
      // this.$store.commit('parcelLoading', true);
      this.$store.commit('setParcels', []);

      this.$store.commit('setTctParcels', []);
      this.$store.commit('setTotParcels', []);

      this.$store.dispatch('resetMapConfig');
    },
    loginAsOrganization(id, reset = false) {
      this.onLogin({ type: 'organization', id, reset });

      if (reset) {
        this.reset();
      }
      const { organizations } = this.$store.state;
      const org = organizations.filter(org => org.id === id)[0];
      if (!org) {
        return;
      }

      const data = { id: org.id, name: org.name, key: org.key };
      this.getAppBarTitle(org.key);
      this.$store.commit('loginAsOrganization', data);
      this.$store.commit('drawer', false);

      this.getOrgPermissions();
      this.getOrgSetting();
    },
    getOrgSetting() {
      const { id } = this.$store.state.loggedInAs;
      org.getSetting(id).then(setting => {
        if (!setting.parcelReference && !this.$store.state.parcel.foundMapConfig) {
          this.$store.commit('setMapConfig', { key: 'showParcelId', value: true });
        }
        this.$store.commit('organizationSetting', setting);
      });
    },
    async loginAsUser(reset = false) {
      this.onLogin({ type: 'user', reset });
      this.allowAllPermission();

      this.$store.commit('loginAsUser');
      this.$store.commit('drawer', false);
      this.$store.commit('setSearchQuery', '');
      this.$store.commit('appBarTitle', 'My Peeps Portal');

      if (reset) {
        this.reset();
      }
    },
    verifyLoggedInOrganization() {
      const { type, id } = this.$store.state.loggedInAs;
      if (type === 'organization') {
        const getOrg = this.$store.state.organizations.filter(o => o.id === id);
        this.getAppBarTitle(getOrg[0]?.key);
        if (!getOrg.length) {
          this.loginAsUser();
        }
      }
    },
    async getOrganizations() {
      this.$store.commit('loadingOrganizations', true);
      const uid = localStorage.getItem('uid');
      const email = localStorage.getItem('email');
      if (!uid) {
        console.warn('uid is required');
        return;
      }
      //get created organizations
      const orgs = [];
      let createdOrganization, addedOrganizations;
      try {
        createdOrganization = await org.list({ user: uid });
      } catch (error) {
        console.warn('Error getting created organizations', error);
      }

      if (createdOrganization && createdOrganization.size) {
        createdOrganization.forEach(item => {
          orgs.push({ ...item.data(), id: item.id });
        });
      }

      let addedOrgs = [];
      try {
        addedOrganizations = await org.listAdded({ email: email });
        if (addedOrganizations && addedOrganizations.size) {
          addedOrganizations.forEach(item => {
            addedOrgs.push(item.id);
          });

          addedOrgs = addedOrgs.map(item => {
            return org.retrieve(item);
          });

          try {
            (await Promise.all(addedOrgs)).forEach(item => {
              orgs.push({ ...item.data(), id: item.id, added: true });
            });
          } catch (error) {
            console.warn(error);
          }
        }
      } catch (error) {
        console.warn('Error getting added organizations', error);
      }

      this.$store.commit('setOrganizations', sort({ items: orgs, field: 'key' }));
      this.verifyLoggedInOrganization();
      this.getOrgPermissions();
      return orgs;
    }
  }
};
