import LogRocket from 'logrocket';
import config from './config';
export default function initializeLogRocket(user) {
  if (!config.logrocket()) {
    console.warn('LogRocket config not found');
    return;
  }

  if (LogRocket._isInitialized) {
    if (user?.uid) {
      LogRocket.identify(user?.uid, {
        email: user?.email
      });
    }
    console.warn('LogRocket already initialized');
    return;
  }

  let initialize = false;
  if (config.isProduction()) {
    initialize = true;
  } else {
    if (user?.profile?.logSession) {
      initialize = true;
    }
  }

  if (!initialize) return;
  if (process.env.NODE_ENV !== 'development') {
    LogRocket.init(config.logrocket());
  }
  if (user?.uid) {
    LogRocket.identify(user?.uid, {
      email: user?.email
    });
  }
}
