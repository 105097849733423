import { CMS } from '../services/cms';
const cms = new CMS();
export default {
  methods: {
    async getAppBarTitle(k) {
      try {
        if (!k) {
          return;
        }
        const key = `AppBarTitle_${k}`;
        const res = await cms.get({ key, type: 'html' });
        if (!res.docs || !res.docs.length) {
          return;
        }
        this.$store.commit('appBarTitle', res.docs[0].data().html);
      } catch (error) {
        console.log(error);
        this.$store.commit('appBarTitle', 'My Peeps Portal');
      }
    }
  }
};
