<template>
  <v-slider v-bind="$attrs" v-model="lazyValue" :thumb-label="thumbLabel"></v-slider>
</template>

<script>
export default {
  props: {
    thumbLabel: {
      type: String,
      default: 'always'
    },
    value: {
      type: [Number, String],
      default: 0
    },
    lazy: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      internalValue: Number(this.value) || 0,
      timeout: null
    };
  },
  computed: {
    lazyValue: {
      get() {
        return this.internalValue;
      },
      set(value) {
        this.internalValue = value;
        if (this.lazy) {
          if (this.timeout) {
            clearTimeout(this.timeout);
          }
          this.timeout = setTimeout(() => {
            this.$emit('input', value);
          }, 200);
        } else {
          this.$emit('input', value);
        }
      }
    }
  }
  // watch: {
  //   internalValue: {
  //     handler(value) {
  //       if (!this.lazy) {
  //         this.$emit('input', value);
  //       } else {
  //         if (this.timeout) {
  //           clearTimeout(this.timeout);
  //         }
  //         this.timeout = setTimeout(() => {
  //           this.$emit('input', value);
  //         }, 200);
  //       }
  //     }
  //   }
  // }
};
</script>

<style>
</style>
