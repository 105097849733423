<script>
import { VTooltip } from 'vuetify/lib';
export default {
  extends: VTooltip,
  props: {
    timeout: {
      type: Number,
      default: 0
    },
    bottom: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      timer: null,
      TIME: 3000
    };
  },
  watch: {
    isActive: {
      handler: 'onTooltipChange'
    }
  },
  methods: {
    clearTimer() {
      clearTimeout(this.timer);
      this.timer = null;
    },
    onTooltipChange() {
      if (!this.isActive) {
        this.clearTimer();
      }
      if (this.timer) {
        return;
      }
      this.timer = setTimeout(() => {
        this.isActive = false;
        this.clearTimer();
      }, this.TIME);
    }
  }
};
</script>

<style>
</style>
