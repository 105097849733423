import AppHeader from '@/components/AppHeader';
import AppFooter from '@/components/AppFooter';

const Parcel = () => import(/* webpackChunkName: "Parcel" */ '@/views/Parcel/Index');
const ParcelList = () => import(/* webpackChunkName: "ParcelList" */ '../views/Parcel/List.vue');
const ParcelCard = () => import(/* webpackChunkName: "ParcelCard" */ '../views/Parcel/Card.vue');
const ParcelMap = () => import(/* webpackChunkName: "ParcelMap" */ '../views/Parcel/Map.vue');
const ParcelIntro = () =>
  import(/* webpackChunkName: "ParcelIntro" */ '@/views/Parcel/ParcelIntro');
const Tax = () => import(/* webpackChunkName: "Tax" */ '@/views/Parcel/Tax/Index');
const Water = () => import(/* webpackChunkName: "Water" */ '@/views/Parcel/Water/Index');
const Septic = () => import(/* webpackChunkName: "Septic" */ '@/views/Parcel/Septic/Index');
const HVAC = () => import(/* webpackChunkName: "HVAC" */ '@/views/Parcel/HVAC/Index');
const Solar = () => import(/* webpackChunkName: "Solar" */ '@/views/Parcel/Solar/Index');
const Land = () => import(/* webpackChunkName: "Land" */ '@/views/Parcel/Land/Index');

const routes = [
  {
    path: '/parcels',
    components: {
      header: AppHeader,
      default: Parcel,
      footer: AppFooter
    },
    children: [
      {
        path: '',
        name: 'ParcelCard',
        component: ParcelCard,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: 'map',
        name: 'ParcelMap',
        component: ParcelMap,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: 'list',
        name: 'ParcelList',
        component: ParcelList,
        meta: {
          requiresAuth: true
        }
      }
    ]
  },
  {
    path: '/parcel-intro',
    name: 'ParcelIntro',
    components: {
      header: AppHeader,
      default: ParcelIntro,
      footer: AppFooter
    },
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/parcels/:id/taxes',
    name: 'Tax',
    components: {
      header: AppHeader,
      default: Tax,
      footer: AppFooter
    },
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/parcels/:id/hvac',
    name: 'HVAC',
    components: {
      header: AppHeader,
      default: HVAC,
      footer: AppFooter
    },
    meta: {
      requiresAuth: true
    }
  },

  {
    path: '/parcels/:id/water',
    name: 'Water',
    meta: {
      requiresAuth: true
    },
    components: {
      header: AppHeader,
      default: Water,
      footer: AppFooter
    }
  },
  {
    path: '/parcels/:id/septic',
    name: 'Septic',
    meta: {
      requiresAuth: true
    },
    components: {
      header: AppHeader,
      default: Septic,
      footer: AppFooter
    }
  },
  {
    path: '/parcels/:id/solar',
    name: 'Solar',
    meta: {
      requiresAuth: true
    },
    components: {
      header: AppHeader,
      default: Solar,
      footer: AppFooter
    }
  },
  {
    path: '/parcels/:id/land',
    name: 'Land',
    meta: {
      requiresAuth: true
    },
    components: {
      header: AppHeader,
      default: Land,
      footer: AppFooter
    }
  },
  {
    path: '/organizations',
    name: 'Organizations',
    meta: {
      requiresAuth: true
    },
    components: {
      header: AppHeader,
      default: () => import('../views/Organizations/Index.vue'),
      footer: AppFooter
    }
  },
  {
    path: '/organizations/new',
    name: 'NewOrganization',
    meta: {
      requiresAuth: true
    },
    components: {
      header: AppHeader,
      default: () => import('../views/Organizations/New.vue'),
      footer: AppFooter
    }
  },
  {
    path: '/organizations/:id',
    name: 'OrganizationDetail',
    meta: {
      requiresAuth: true
    },
    components: {
      header: AppHeader,
      default: () => import('../views/Organizations/Detail.vue'),
      footer: AppFooter
    }
  }
];
export default routes;
