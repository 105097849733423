export default {
  appName: 'Peeps Portal',
  deployment: process.env.VUE_APP_DEPLOYMENT,
  productionBranches: ['main', 'beta'],
  developmentBranches: ['dev'],

  baseURL: 'https://www.peepsportal.org',
  emailDomain: 'peepsportal.org',
  mapboxToken:
    'pk.eyJ1IjoiY2xhc3NpY25ldXBhbmUiLCJhIjoiY2tubmJuNm1iMHhrNDJwcGZsNXM0bXgxcyJ9.eJfcXAnt_ukVCkrXFduZXw',

  galleryIDs: {
    development: '9aD4Z140CFl7RhaS8N4X',
    production: 'sSj9yMXar8yXXYr9svFv'
  },

  apiURL: {
    production: {
      cloudFunction: 'https://us-central1-property-analysis-29b2f.cloudfunctions.net/',
      cloudFunctionAPI: 'https://us-central1-property-analysis-29b2f.cloudfunctions.net/api',
      solar:
        'https://us-central1-test-truro.cloudfunctions.net/FirebaseJsonApi?path=MA/Truro/solar',
      truroAPI: 'https://us-central1-test-truro.cloudfunctions.net',
      metrics: 'https://us-central1-property-analysis-29b2f.cloudfunctions.net/metrics',
      email: 'https://us-central1-property-analysis-29b2f.cloudfunctions.net/emailCMS',
      login: 'https://us-central1-property-analysis-29b2f.cloudfunctions.net/login'
    },
    development: {
      cloudFunction: 'https://us-central1-freelancer-brian-b.cloudfunctions.net',
      cloudFunctionAPI: 'https://us-central1-freelancer-brian-b.cloudfunctions.net/api',
      solar:
        'https://us-central1-test-truro.cloudfunctions.net/FirebaseJsonApi?path=MA/Truro/solar',
      truroAPI: 'https://us-central1-test-truro.cloudfunctions.net',
      metrics: 'https://us-central1-freelancer-brian-b.cloudfunctions.net/metrics',
      email: 'https://us-central1-freelancer-brian-b.cloudfunctions.net/emailCMS',
      login: 'https://us-central1-freelancer-brian-b.cloudfunctions.net/login'
    }
  },

  firebase: {
    production: {
      apiKey: 'AIzaSyCWTg3cDv3zhqo2QxtDi6wZsrv_YKWh6Sg',
      authDomain: 'property-analysis-29b2f.firebaseapp.com',
      databaseURL: 'https://property-analysis-29b2f-default-rtdb.firebaseio.com',
      projectId: 'property-analysis-29b2f',
      storageBucket: 'property-analysis-29b2f.appspot.com',
      messagingSenderId: '482032857621',
      appId: '1:482032857621:web:dece6b892abaa70114d41c',
      measurementId: 'G-6RZ7D9MGJ1'
    },
    development: {
      apiKey: 'AIzaSyC3AIhc1wG-ofnt6s70BcidAXdnNRV5PzE',
      authDomain: 'freelancer-brian-b.firebaseapp.com',
      databaseURL: 'https://freelancer-brian-b-default-rtdb.firebaseio.com',
      projectId: 'freelancer-brian-b',
      storageBucket: 'freelancer-brian-b.appspot.com',
      messagingSenderId: '149556519970',
      appId: '1:149556519970:web:18c6bc034bd61c9049167b',
      measurementId: 'G-WQ943MB4FW'
    },
    testTruro: {
      apiKey: "AIzaSyAgZiUKt2UmKHEnPmPbX7byM1MwvMpc6G8",
      authDomain: "test-truro.firebaseapp.com",
      databaseURL: "https://test-truro.firebaseio.com",
      projectId: "test-truro",
      storageBucket: "test-truro.appspot.com",
      messagingSenderId: "401094148937",
      appId: "1:401094148937:web:5758ae3e82a7a878055098"
    }
  },

  firestore: {
    parcel: 'parcels',
    folder: 'folders',
    file: 'files',

    cmsFile: 'cms_files',
    cmsFolder: 'cms_folders',

    land: 'land',
    landActivity: 'landActivity',
    cms: 'cms',
    versions: 'versions',
    form: 'forms'
  },

  keys: {
    contactUsEmails: 'ContactUsEmails'
  },

  storage: {
    driveBucketDev: '',
    driveBucket: ''
  },
  driveFolderName: 'drive',
  driveFolderId: 'peepsAdmin',

  isProduction() {
    return this.productionBranches.includes(this.deployment);
  },

  storageDriveBucket() {
    if (this.productionBranches.includes(this.deployment)) {
      return this.storage.driveBucket;
    }
    if (this.developmentBranches.includes(this.deployment)) {
      return this.storage.driveBucketDev;
    }
    return '';
  },

  firebaseConfig() {
    if (this.isProduction()) {
      return this.firebase.production;
    } else {
      return this.firebase.development;
    }
  },

  api() {
    if (this.isProduction()) {
      return this.apiURL.production;
    } else {
      return this.apiURL.development;
    }
  },

  galleryID() {
    if (this.isProduction()) {
      return this.galleryIDs.production;
    } else {
      return this.galleryIDs.development;
    }
  },

  sentry() {
    if (process.env.NODE_ENV === 'development') return;
    if (this.isProduction()) {
      return 'https://43b6ff471e3b4a72b31a44c10db7e13b@o299011.ingest.sentry.io/5849036';
    }
    return '';
  },
  email: 'contact@peepsportal.org', // eg: contact@truronews.org, info@docstruro.org

  organizations: {
    dev: {
      tct: 'ZTMzCdekMTKdm0RDSK0u',
      tot: 'M00SAvxZf3pr9RWR3FE2'
    },
    prod: {
      tct: 'uFkHq9DVHwcSzzq7svcA',
      tot: 'm8ZYCNVYwJ16Flv6kUjn'
    }
  },
  getOrganization() {
    if (this.isProduction()) {
      return this.organizations.prod;
    } else {
      return this.organizations.dev;
    }
  },

  orientation: false,
  photoStation: !!process.env.VUE_APP_PHOTO_STATION,

  logrocketProjects: {
    main: 'peeps-portal/peeps-portal',
    dev: 'peeps-portal/peeps-portal'
  },
  logrocket() {
    if (this.deployment === 'dev') {
      return this.logrocketProjects.dev;
    }
    return this.logrocketProjects.main;
  },
  subParcelLinkGroup: 'linkedGroups',
  startUpYearEnd: '2023-01-01'
};
