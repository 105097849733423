<template>
  <v-app>
    <router-view name="header" />
    <PermissionLocationHelp />
    <PermissionOrientation />
    <router-view :key="$route.fullPath" />
    <router-view name="footer" />
    <v-snackbar v-bind="$store.state.snackbar">
      {{ $store.state.snackbar.message }}
      <template v-slot:action>
        <v-btn
          color="white"
          text
          @click="$store.commit('updateSnackbar', { key: 'value', value: false })"
          icon
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </v-app>
</template>

<script>
import LogRocket from 'logrocket';
import firebase from 'firebase/app';
import 'firebase/auth';
import watcher from '@/mixins/watcher';
import config from './config';
import accMixin from '@/mixins/account';
import Geolocation from './services/geolocation';
import Orientation from './services/orientation';
import { throttle } from 'throttle-debounce';

export default {
  mixins: [watcher, accMixin],
  name: 'App',

  components: {
    PermissionLocationHelp: () => import('./components/Permission/LocationHelp.vue'),
    PermissionOrientation: () => import('./components/Permission/Orientation.vue')
  },

  metaInfo: {
    title: 'Peeps Portal',
    titleTemplate: '%s'
  },

  async beforeMount() {
    let token = localStorage.getItem('token');
    if (token) {
      this.$axios.defaults.headers.common['authorization'] = `Bearer ${token}`;
    }

    firebase.auth().onIdTokenChanged(async token => {
      if (token) {
        token = await token.getIdToken();
        localStorage.setItem('token', token);
        this.$axios.defaults.headers.common['authorization'] = `Bearer ${token}`;
        setTimeout(() => {
          this.updateLoginHistory(true);
        }, 7000);
      }
    });

    firebase.auth().onAuthStateChanged(async user => {
      if (user) {
        localStorage.setItem('uid', user.uid);
        localStorage.setItem('email', user.email);
        localStorage.setItem('userEmail', user.email);
        this.$store.commit('auth', true);

        this.checkAdmin();
        this.checkSolarAdmin();
        this.watchAll();

        this.getOrganizations();
        this.checkLoggedInAccount();

        if (this.$store.state.redirect) {
          if (this.$route.path == '/login' || this.$route.path == '/signup') {
            let next = this.$route.query.next || '/';
            this.$router.push(next);
          }
        }
      } else {
        localStorage.removeItem('uid');
        this.$store.commit('auth', false);
        if (this.$route.meta.requiresAuth) {
          this.$router.push('/login');
        }
      }
    });
  },
  created() {
    setTimeout(() => {
      this.changeFavicon();
    }, 100);
    setTimeout(() => {
      this.$store.dispatch('fetchLinkedGroups');
    }, 5000);
  },
  methods: {
    askPermissions() {
      setTimeout(() => {
        const geolocation = new Geolocation();
        geolocation.onChange(
          throttle(100, gps => {
            this.$store.commit('setGps', gps);
          }),
          error => {
            this.$store.commit('setDialog', { key: 'locationPermissionError', value: true });
            LogRocket.track('LocationPermissionDenied', {
              error: error.message
            });
          }
        );
        if (config.orientation) {
          const orientation = new Orientation();
          orientation.onChange(
            throttle(100, data => {
              if (!this.$store.state.orientationPermission && data?.heading) {
                this.$store.commit('setOrientationPermission', true);
              }
              this.$store.commit('setOrientation', data);
            })
          );
        }
      }, 5000);
    },

    changeFavicon() {
      if (config.deployment === 'dev') {
        this.setFavicon('/favicon-dev.ico');
      } else if (config.deployment === 'beta') {
        this.setFavicon('/favicon-beta.ico');
      }
    }
  },
  watch: {
    '$store.state.auth': {
      handler(value) {
        if (value) {
          this.askPermissions();
        }
      },
      immediate: true
    }
  }
};
</script>

<style></style>
