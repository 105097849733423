import { getFirestore, timestamp } from './firebase';
import {
  updatePermission,
  removePermissions,
  assignTeam,
  removeTeam,
  updateUserDetail,
  removeUserDetail
} from '../utils/organization';
import { CMS } from './cms';
import Feature from './feature';
import _ from 'lodash';
import { sort } from '../utils';

const feature = new Feature();
const cms = new CMS();

const DEFAULT_SETTING = {
  parcelReference: true,
  team: true
};

class Organization {
  constructor() {
    this.collection = 'organizations';
    this.ref = getFirestore().collection(this.collection);
    this.collectionPermission = 'organization_permissions';
    this.permissionRef = getFirestore().collection(this.collectionPermission);
    this.collectionMonitoringFrequency = 'monitoring_frequency';
    this.monitoringFrequencyRef = getFirestore().collection(this.collectionMonitoringFrequency);
    this.collectionSetting = 'organization_setting';
    this.refSetting = getFirestore().collection(this.collectionSetting);
  }

  create({ key, name, user, features, userFullName, userEmail }) {
    const data = {
      name,
      key,
      user,
      features: features || {},
      ...timestamp(),
      userFullName,
      userEmail
    };
    return this.ref.add(data);
  }

  retrieve(id) {
    return this.ref.doc(id).get();
  }

  updateFeatures({ id, features }) {
    return new Promise(async (resolve, reject) => {
      try {
        const q = await this.retrieve(id);
        if (q.exists) {
          const data = { ...q.data() };
          data.features = features;
          this.update(id, data)
            .then(resolve)
            .catch(reject);
        } else {
          reject('organization does not exists');
        }
      } catch (error) {
        reject(error);
      }
    });
  }

  list({ user } = {}, cb) {
    //returns list of created organization by user
    let q = this.ref;

    if (user) {
      q = q.where('user', '==', user);
    }

    if (cb) {
      return q.onSnapshot(cb);
    } else {
      return q.get();
    }
  }

  listAdded({ email }, cb) {
    //returns list of organization that user belongs to
    let q = this.permissionRef;

    if (email) {
      q = q.where('users', 'array-contains', email);
    }

    if (cb) {
      return q.onSnapshot(cb);
    } else {
      return q.get();
    }
  }
  update(id, data) {
    return this.ref.doc(id).set({ ...data, ...timestamp(false, true) });
  }

  defaultPermData() {
    return {
      create: [],
      read: [],
      update: [],
      delete: [],
      users: [],
      ...timestamp()
    };
  }

  initializePermission(id) {
    return this.permissionRef.doc(id).set(this.defaultPermData());
  }

  getPermissions(id, cb) {
    const q = this.permissionRef.doc(id);
    if (cb) {
      return q.onSnapshot(cb);
    } else {
      return q.get();
    }
  }

  updatePermission({ id, email, permissions, team, fullName, userData }) {
    return new Promise(async (resolve, reject) => {
      const permission = await this.getPermissions(id);
      if (permission.exists) {
        let permData = permission.data();
        permData = updatePermission({ email, permissions, data: permData, fullName });
        permData = assignTeam({ email, data: permData, team });
        permData = updateUserDetail({ data: permData, email, detail: userData });
        this.permissionRef.doc(id).set(permData);
        resolve();
      } else {
        this.initializePermission(id);
        reject('permission does not exists');
      }
    });
  }

  removeMember({ id, email }) {
    return new Promise(async (resolve, reject) => {
      const permission = await this.getPermissions(id);
      if (permission.exists) {
        let permData = permission.data();
        const members = [...(permData.members || [])];
        const index = members.findIndex(i => {
          return String(i.email).toLowerCase() === String(email).toLowerCase();
        });
        if (index !== -1) {
          members.splice(index, 1);
        }
        permData.members = members;
        this.permissionRef.doc(id).set(permData);
        resolve();
      } else {
        reject('permission does not exists');
      }
    });
  }

  getMonitoringFrequency(id) {
    return new Promise((resolve, reject) => {
      this.monitoringFrequencyRef
        .doc(id)
        .get()
        .then(res => {
          if (res.exists) {
            resolve({ id: res.id, data: res.data() });
          } else {
            reject(new Error('Not found'));
          }
        })
        .catch(error => reject(error));
    });
  }

  updateMonitoringFrequency(id, data) {
    return this.monitoringFrequencyRef.doc(id).set(data);
  }

  /**
   *
   * @returns {DEFAULT_SETTING} organization default setting
   */

  static defaultSetting() {
    return DEFAULT_SETTING;
  }

  /**
   *
   * @param {DEFAULT_SETTING} setting
   * @returns {DEFAULT_SETTING}
   */

  static setting(setting) {
    return { ...DEFAULT_SETTING, ...(setting || {}) };
  }

  /**
   *
   * @param {Number} id
   * @param {DEFAULT_SETTING} setting
   */

  updateSetting(id, setting) {
    this.refSetting.doc(id).set(Organization.setting(setting));
  }

  /**
   *
   * @param {Number} id Organization ID
   * @returns {Promise<DEFAULT_SETTING>} Organization setting
   */

  getSetting(id, cb) {
    return new Promise((resolve, reject) => {
      const q = this.refSetting
        .doc(id);

      if (cb) {
        resolve(q.onSnapshot(res => {
          const data = res.exists ? res.data() : null;
          resolve(cb(data));
        }))
      }
      else {
        q.get().then(res => {
          const data = res.exists ? res.data() : null;
          resolve(Organization.setting(data));
        })
          .catch(err => reject(err));
      }

    });
  }

  async updatePreserves(id, preserves) {
    const q = await this.retrieve(id);
    if (q.exists) {
      const data = q.data();
      data.preserves = preserves;
      await this.update(id, data);
    }
  }

  async landPreserves(id) {
    const q = await this.retrieve(id);
    if (q.exists) {
      const data = q.data();
      let preserves = data.preserves || [];
      preserves = preserves.map(item => {
        return {
          ...item,
          value: _.camelCase(item.text)
        };
      });
      preserves = sort({ items: preserves, field: 'text' });
      return preserves;
    } else {
      return [];
    }
  }

  memberDetail(data, email) {
    const detail = (data.detail || {})[email] || {};
    return {
      email,
      fullName: data.fullName[email] || '',
      deviceDesktop: detail.deviceDesktop || '',
      deviceMobile: detail.deviceMobile || '',
      homeCoordinates: detail.homeCoordinates || '',
      phoneNumber: detail.phoneNumber || '',
      team: data.team[email] || '',
      permissions: {
        create: data.create.includes(email),
        read: data.read.includes(email),
        update: data.update.includes(email),
        delete: data.delete.includes(email),
        addMember: data.addMember.includes(email),
        editPhotoGps: data.editPhotoGps.includes(email)
      },
      inactive: false,
      removed: false
    };
  }

  migrate(id) {
    this.permissionRef
      .doc(id)
      .get()
      .then(doc => {
        if (!doc.exists) return;
        let data = doc.data();
        if (!data) return;
        const members = [];
        data.users.forEach(email => {
          members.push(this.memberDetail(data, email));
        });
        data = { ...data, ...this.permissions(members) };
        data.members = members;
        this.permissionRef.doc(id).set(data);
      });
  }

  /**
   *
   * @param {Array<object>} members
   * @returns
   */
  permissions(members) {
    if (!members) members = [];
    const res = { create: [], read: [], update: [], delete: [], addMember: [], users: [], editPhotoGps: [] };
    members.forEach(member => {
      if (!member.permissions) member.permissions = {};
      const email = String(member.email).toLowerCase();
      res.users.push(email);
      Object.keys(member.permissions).forEach(permission => {
        if (member.permissions[permission]) {
          if (permission === 'read' && member.inactive) {
            res[permission].push(email);
          }
          if (!member.inactive) {
            res[permission].push(email);
          }
        }
      });
    });
    return res;
  }

  deactive({ id, email }) {
    return new Promise(async (resolve, reject) => {
      const permission = await this.getPermissions(id);
      if (permission.exists) {
        let permData = permission.data();

        if (!permData.members) permData.members = [];

        const index = permData.members.findIndex(i => i.email === email);
        if (index !== -1) {
          permData.members[index].inactive = !permData.members[index].inactive;
        }

        permData = { ...permData, ...this.permissions(permData.members) };

        this.permissionRef.doc(id).set(permData);
        resolve();
      } else {
        reject('permission does not exists');
      }
    });
  }

  addMember(id, member) {
    return new Promise((resolve, reject) => {
      this.permissionRef
        .doc(id)
        .get()
        .then(doc => {
          if (!doc.exists) reject();
          let data = doc.data();

          if (!data.members) {
            data.members = [];
          }

          member.email = String(member.email).toLowerCase();

          const existingMembers = data.members

          data.members.push(member);
          data = { ...data, ...this.permissions(data.members) };

          const memberExists = existingMembers.filter(i=>i?.email===member?.email).length

          if(memberExists){
            reject(`${member?.email} already exists`)
            return
          }

          this.permissionRef
            .doc(id)
            .set(data)
            .then(resolve);
        })
        .catch(error => reject(error));
    });
  }

  updateMember({ id, member }) {
    return new Promise((resolve, reject) => {
      this.permissionRef
        .doc(id)
        .get()
        .then(doc => {
          if (!doc.exists) reject();
          let data = doc.data();

          if (!data.members) {
            data.members = [];
          }

          member.email = String(member.email).toLowerCase();

          const index = data.members.findIndex(
            i => String(i.email).toLowerCase() === String(member.email).toLowerCase()
          );
          data.members[index] = { ...data.members[index], ...member };

          data = { ...data, ...this.permissions(data.members) };

          this.permissionRef
            .doc(id)
            .set(data)
            .then(resolve);
        })
        .catch(error => reject(error));
    });
  }
}
export default Organization;
