<template>
  <div class="scrollable-container" :class="{ outlined }" :style="{ 'max-height': `${maxHeight}` }">
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    maxHeight: {
      type: String,
      required: true
    },
    outlined: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style scoped lang="scss">
$border-color: rgb(207, 204, 204);

.scrollable-container {
  overflow-y: scroll;
}

::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

.outlined {
  border: 1px solid $border-color;
  border-radius: 5px;
  padding: 10px;
}
</style>
