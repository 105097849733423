<template>
  <fieldset :class="{ 'fieldset--disabled': isDisabled }">
    <slot :toggle="toggle" :show="visible"></slot>
  </fieldset>
</template>

<script>
export default {
  props: {
    outlined: {
      type: Boolean,
      default: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    show: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      visible: true
    };
  },
  computed: {
    isDisabled() {
      if (!this.visible) {
        return true;
      }
      return !this.outlined || this.disabled;
    }
  },
  created() {
    this.visible = this.show;
  },
  methods: {
    toggle() {
      this.visible = !this.visible;
    }
  }
};
</script>

<style scoped lang="scss">
$color-outline: rgb(179, 166, 166);
@import '../../../design/_colors.scss';

fieldset:not(.fieldset--disabled) {
  border: 1px solid $color-outline;
  border-radius: 10px;
  padding: 10px;
}

.fieldset--disabled {
  border: 0px;
  margin-top: 5px;

  legend {
    margin-left: 11px;
  }
}

legend {
  background-color: rgb(248, 244, 244);
  color: $color-primary;
  border-radius: 5px;
  padding: 5px;
}
</style>
