import { DEFAULT_MAP_STYLE } from '../mixins/map';
const PARCEL_DEFAULT_FILTER = {
  team: '',
  acres: false,
  scale: true,
  reference: true,
  tct: false,
  tot: false,
  hash: '',
  style: DEFAULT_MAP_STYLE,
  showParcelId: false,
  monitoringDueAny: true,
  monitoringDue: 0,
  monitoringDueMissing: false,
  coordinates: null,
  boundary: false,
  land: ['feeSimple', 'cr'],
  landPreserve: '',
  showPreserve: 'selected',
  showContactColumn: false,
  showPreserveColumn: false,
  monitoringColor: true,
  showLastActivity: false,
  landIssue: 'ALL', //ALL, ISSUE_EXISTS
  sortBy: 'az', //az, recentCreated, recentUpdated
  linkedGroupGeometry: [],
  linkedGroupGeometryLabel: [],
  totMode: 'nearby' // nearby | all
};
export { PARCEL_DEFAULT_FILTER };
