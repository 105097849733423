<script>
import { VProgressCircular } from 'vuetify/lib';
export default {
  extends: VProgressCircular,
  props: {
    indeterminate: {
      type: Boolean,
      default: true
    },
    width: {
      type: Number,
      default: 4
    },
    size: {
      type: Number,
      default: 40
    },
    color: {
      type: String,
      default: 'primary'
    }
  }
};
</script>

<style></style>
