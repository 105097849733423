import AdminHeader from '@/components/AdminHeader';
import AppFooter from '@/components/AppFooter';

const routes = [
  {
    path: '/admin',
    redirect: '/admin/dashboard'
  },
  {
    path: '/admin/dashboard',
    name: 'Dashboard',
    meta: {
      admin: true
    },
    components: {
      header: AdminHeader,
      default: () => import(/* webpackChunkName: "Dashboard" */ '@/views/Admin/Dashboard'),
      footer: AppFooter
    }
  },
  {
    path: '/admin/user/:id',
    name: 'UserDetail',
    meta: {
      admin: true
    },
    components: {
      header: AdminHeader,
      default: () => import(/* webpackChunkName: "UserDetail" */ '@/views/Admin/User/Index'),
      footer: AppFooter
    }
  },
  {
    path: '/admin/sandbox',
    name: 'Sandbox',
    meta: {
      admin: true
    },
    components: {
      header: AdminHeader,
      default: () => import(/* webpackChunkName: "Sandbox" */ '@/views/Admin/Sandbox/Index'),
      footer: AppFooter
    }
  },
  {
    path: '/admin/email',
    meta: {
      admin: true
    },
    components: {
      header: AdminHeader,
      default: () => import(/* webpackChunkName: "AdminEmail" */ '../views/Admin/Email/Index.vue'),
      footer: AppFooter
    },
    children: [
      {
        path: '',
        name: 'AdminEmail',
        components: {
          header: AdminHeader,
          default: () =>
            import(/* webpackChunkName: "AdminSendEmail" */ '../views/Admin/Email/Send.vue'),
          footer: AppFooter
        }
      },
      {
        path: 'mailing-list',
        components: {
          header: AdminHeader,
          default: () =>
            import(
              /* webpackChunkName: "AdminMailingList" */ '../views/Admin/Email/MailingList.vue'
            ),
          footer: AppFooter
        }
      },
      {
        path: 'html',
        components: {
          header: AdminHeader,
          default: () =>
            import(/* webpackChunkName: "AdminEmailHtml" */ '../views/Admin/Email/Html.vue'),
          footer: AppFooter
        }
      },
      {
        path: 'history',
        components: {
          header: AdminHeader,
          default: () =>
            import(/* webpackChunkName: "AdminEmailHistory" */ '../views/Admin/Email/History.vue'),
          footer: AppFooter
        }
      }
    ]
  }
];
export default routes;
