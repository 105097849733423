<template>
  <div>
    <v-text-field v-if="type === 'text'" v-bind="$attrs" @input="onInput"></v-text-field>
    <v-textarea v-if="type === 'textarea'" v-bind="$attrs" @input="onInput"></v-textarea>
  </div>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      required: true
    }
  },
  methods: {
    onInput(value) {
      this.$emit('input', value);
    }
  }
};
</script>

<style>
</style>
