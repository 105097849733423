import Vue from 'vue';
import VueRouter from 'vue-router';

import AppHeader from '@/components/AppHeader.vue';
import AppFooter from '@/components/AppFooter.vue';
// import admin from './admin';
import adminRoutes from './admin.router';
import aboutRoutes from './about.router';
import featureRoutes from './feature.router';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    components: {
      header: AppHeader,
      default: () => import(/* webpackChunkName: "Home" */ '@/views/Home'),
      footer: AppFooter
    }
  },
  {
    path: '/login',
    name: 'Login',
    components: {
      header: AppHeader,
      default: () => import(/* webpackChunkName: "Login" */ '@/views/Login'),
      footer: AppFooter
    }
  },
  {
    path: '/signup',
    name: 'Signup',
    components: {
      header: AppHeader,
      default: () => import(/* webpackChunkName: "Signup" */ '@/views/Signup'),
      footer: AppFooter
    }
  },
  {
    path: '/profile',
    name: 'Profile',
    components: {
      header: AppHeader,
      default: () => import(/* webpackChunkName: "Profile" */ '@/views/Profile'),
      footer: AppFooter
    },
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/account-recovery',
    name: 'AccountRecovery',
    components: {
      header: AppHeader,
      default: () => import(/* webpackChunkName: "AccountRecovery" */ '@/views/AccountRecovery'),
      footer: AppFooter
    }
  },
  {
    path: '/contact',
    name: 'Contact',
    meta: {
      requiresAuth: true
    },
    components: {
      header: AppHeader,
      default: () => import(/* webpackChunkName: "Contact" */ '@/views/Contact'),
      footer: AppFooter
    }
  },
  {
    path: '/help',
    name: 'Help',
    components: {
      header: AppHeader,
      default: () => import(/* webpackChunkName: "Help" */ '@/views/Help'),
      footer: AppFooter
    }
  },
  {
    path: '/journals',
    name: 'Journals',
    components: {
      header: AppHeader,
      default: () => import(/* webpackChunkName: "Journals" */ '@/views/Journals'),
      footer: AppFooter
    }
  },
  {
    path: '/nitrate-articles/:id',
    name: 'NitrateArticle',
    components: {
      header: AppHeader,
      default: () =>
        import(/* webpackChunkName: "NitrateArticle" */ '@/views/NitrateArticleDetail'),
      footer: AppFooter
    }
  },
  {
    path: '/auth',
    name: 'Auth',
    components: {
      header: AppHeader,
      default: () => import(/* webpackChunkName: "Auth" */ '@/views/Auth'),
      footer: AppFooter
    }
  },
  {
    path: '/gallery',
    name: 'Gallery',
    components: {
      header: AppHeader,
      default: () => import(/* webpackChunkName: "Auth" */ '@/views/Gallery'),
      footer: AppFooter
    }
  },
  {
    path: '/404',
    name: 'ErrorNotFound',
    components: {
      header: AppHeader,
      default: () => import(/* webpackChunkName: "ErrorNotFound" */ '@/views/ErrorNotFound'),
      footer: AppFooter
    }
  },
  ...adminRoutes,
  ...aboutRoutes,
  ...featureRoutes,
  {
    path: '/:pathMatch(.*)*',
    name: 'Cms',
    components: {
      header: AppHeader,
      default: () => import(/* webpackChunkName: "Cms" */ '@/views/Cms'),
      footer: AppFooter
    }
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  let auth = !!localStorage.getItem('auth');
  let admin = !!localStorage.getItem('admin');

  const parcelViews = ['ParcelCard', 'ParcelList', 'ParcelMap'];
  if (from.name) {
    if (parcelViews.includes(from.name) && parcelViews.includes(to.name)) {
      localStorage.setItem('lastViewMode', to.name);
    } else if (parcelViews.includes(to.name) && !parcelViews.includes(from.name)) {
      let lastViewMode = localStorage.getItem('lastViewMode') || 'ParcelCard';
      if (!parcelViews.includes(lastViewMode)) lastViewMode = 'ParcelCard';
      if (lastViewMode !== to.name) {
        return next({ name: lastViewMode, query: { highlight: to.query.highlight } });
      }
    }
  }

  if (to.path.includes('admin') && !from.path.includes('admin')) {
    localStorage.setItem('fromPathAdmin', from.fullPath);
  }

  if (to.meta.requiresAuth) {
    if (!auth) {
      next({ path: `/login?next=${to.fullPath}` });
    } else {
      next();
    }
  } else if (to.meta.admin) {
    if (!admin) {
      next({ path: `/login?next=${to.fullPath}` });
    } else {
      next();
    }
  } else {
    if (auth && (to.name == 'Login' || to.name == 'Signup')) {
      next({ path: '/' });
    }
  }
  next();
});

router.onError(error => {
  if (/loading chunk \d* failed./i.test(error.message)) {
    window.location.reload();
  }
});

export default router;
